import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getusers } from "../Redux/action/userAction";
import StudentStatusModal from "./studentStatusModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Details } from "./Drawer/Details";

const ActiveStudent = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const [data, setdata] = useState([]);
  const [singleData, setSingleData] = useState({});

  useEffect(() => {
    const headers = { Authorization: `Bearer ${auth.token}` };

    axios
      .get(`${process.env.API_URL}/studentstatus/${id}`, { headers })
      .then((response) => {
        setdata(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id, auth]);

  const downloadCSV = (data) => {
    const filteredData = data?.map(({ userId, ...rest }) => rest);
    const csvData = filteredData
      ?.map((row) => Object.values(row).join(","))
      .join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${id}.csv`);
    link.click();
  };

  // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, [data]);

  // console.log(data)

  const [studentdata, setstudentdata] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  // console.log(data)
  const filteredItems = data?.filter(
    (item) =>
      (item?.userData &&
        item?.userData?.username
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.userData &&
        item?.userData?.name.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handlefilter = (e) => {
    setFilterText(e.target.value);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="input-group w-50">
        <input
          id="search-input"
          type="search"
          className="form-control"
          placeholder="Search Name Or ID"
          onChange={(e) => handlefilter(e)}
        />
        <button id="search-button" type="button" className="btn btn-primary">
          <i className="fa fa-search"></i>
        </button>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.full_name
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
      }
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  useEffect(() => {
    dispatch(getusers(auth));
  }, []);

  const columns = [
    {
      name: "Full Name",
      selector: (row) => (
        <div
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "blue",
          }}
          data-bs-toggle="offcanvas"
          data-bs-target="#demo"
          onClick={() => setSingleData(row)}
        >
          {row?.userData && row?.userData.name}
        </div>
      ),
      sortable: true,
    },

    {
      name: "Student ID",
      selector: (row) => row?.userData && row?.userData.username,
      sortable: true,
    },
    // { name: "Email", selector: (row) => row.userId.email, sortable: true },
    { name: "Batch Time", selector: (row) => row.batch_time, sortable: true },
    { name: "Section", selector: (row) => row.section, sortable: true },
    // { name: "Teacher", selector: (row) => row.teacher, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "ID Card No", selector: (row) => row.id_card_no, sortable: true },
    // { name: "Remarks", selector: (row) => row.remarks, sortable: true },

    {
      name: "Action",
      cell: (row) => (
        <div className="actions">
          <button
            onClick={(e) =>
              setstudentdata({
                _id: row._id,
                batch_time: row.batch_time,
                certificate: row.certificate,
                section: row.section,
                id_card_no: row.id_card_no,
                teacher: row.teacher,
                status: row.status,
                remarks: row.remarks,
                userId: row?.userData && row.userData._id,
                name: row?.userData && row?.userData.name,
                email: row?.userData && row.userData.email,
                username: row?.userData && row.userData.username,
              })
            }
            className="btn btn-sm bg-success-light ms-2"
            data-bs-toggle="modal"
            data-bs-target="#studentstatusmodal"
          >
            <i className="fas fa-pen"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* <!-- Main Wrapper --> */}
      <div>
        <Details data={singleData} />
        <StudentStatusModal
          studentdata={studentdata}
          setstudentdata={setstudentdata}
        />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Students</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
                <div className="col-auto text-end float-end me-auto">
                  <a
                    onClick={() => downloadCSV(data)}
                    className="btn btn-outline-primary ms-2"
                  >
                    <i className="fas fa-download"></i> Download
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- /Page Header --> */}

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataTable
                        title="Student List"
                        columns={columns}
                        pagination
                        highlightOnHover
                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                        clearSelectedRows={toggleCleared}
                        data={filteredItems}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Footer --> */}
          <footer>
            <p>Copyright © 2023 Ifastacademy.</p>
          </footer>
          {/* <!-- /Footer --> */}
        </div>
        {/* <!-- /Page Wrapper --> */}
      </div>
      {/* <!-- /Main Wrapper --> */}
    </>
  );
};

export default ActiveStudent;
