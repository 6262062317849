import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { PostReferral } from '../../Redux/action/referralsAction';

export default function WithdrawalModal({withdrawaldata,setwithdrawaldata}) {
  const auth = useSelector((state) => state.authReducer);
    
    const dispatch = useDispatch()

    const handleSubmit = (withdrawaldata) =>{
        dispatch(PostReferral(withdrawaldata,auth, {refeeralstatus:"withdrawal"}))
        
    } 
  return (
    <div className="modal fade" id="withdrawalmodal" aria-labelledby="withdrawalmodal" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="withdrawalmodal">Withdrawal funds</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
        Are you certain about withdrawing the funds? This action cannot be undone.

        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(e)=>handleSubmit(withdrawaldata)}>Proceed</button>
        </div>
      </div>
    </div>
  </div>
  )
}
