
import React from 'react';

const PdfViewer = ({Url}) => {
  const downloadResume = () => {
    window.open(Url, '_blank');
  };

  return (
    <div className="col-md-4 mb-3">
    <button type="button" onClick={downloadResume} class="btn btn-success">View Your Certificate</button>
    </div>
  );
};

export default PdfViewer;
