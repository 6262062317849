import axios from "axios"
import { toast } from "react-toastify"


export const ADD_COURSE = "ADD_COURSE"
export const GET_COURSE_BYID = "GET_COURSE_BYID"


export const addcourse=(data)=>{
    return {
        type:ADD_COURSE,
        payload:data
    }
}

export const addcousebyid=(data)=>{
    return {
        type:GET_COURSE_BYID,
        payload:data
    }
}



export const getCourse=()=>{
    return async(dispatch, getState, api) => {
         try {
             let res=await fetch(`${process.env.API_URL}/course`)
             let data=await res.json()
            //  console.log(data)
             dispatch(addcourse(data))
         } 
         catch (error) {
            console.log(error)
         }
      }
}

// export const getCourseById=(id)=>{
//     return async(dispatch, getState, api) => {
//          try {
//              let res=await fetch(`${process.env.API_URL}/course/${id}`)
//              let data=await res.json()
//             //  console.log("coursAction",data)
//              dispatch(addcousebyid(data))
//          } 
//          catch (error) {
//             console.log(error)
//          }
//       }
// }



export const deleteCourse = (id)=>{
    return async(dispatch, getState, api)=>{
        
        try {
            const toastid = toast.loading("Course Deleting..." ,{position: "top-center",})
            await fetch(`${process.env.API_URL}/course/${id}`,{
                    method:"DELETE",
                   
                    headers:{
                        'Content-Type': 'application/json'
                    }
            })
            toast.update(toastid, {render: "Course deleted successfully", type: "success", isLoading: false,position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                  
            dispatch(getCourse())
        } 
        catch (error) {
            console.log(error)
        }
}
}

export const PostCourse=(data)=>{
    return async(dispatch, getState, api) => {
        try{
            const id = toast.loading("Course adding..." ,{position: "top-center",})
    
             await axios.post(`${process.env.API_URL}/course`, data)
             .then(() => {
                  dispatch(getCourse())
                  toast.update(id, {render: "Course added successfully", type: "success", isLoading: false,position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",});
                  
                })
                
                
              .catch((error) => {
                console.log(error)
              });
          }
          catch(err){
            console.log(err)
                   
          }
     }
}

export const PatchCourse=(data,id)=>{
    return async(dispatch, getState, api) => {

        const toastid = toast.loading("Course updating..." ,{position: "top-center",})
        try {
            
            await fetch(`${process.env.API_URL}/course/${id}`,{
                    method:"PATCH",
                    body:JSON.stringify(data),
                   
                    headers:{
                        'Content-Type': 'application/json'
                    }
            })

            toast.update(toastid, {render: "Course updated successfully", type: "success", isLoading: false,position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        dispatch(getCourse())
            
        } 
        catch (error) {
            toast.update(toastid, {render: error.response?.data, type: "error", isLoading: false,position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
            console.log(error)
        }
}
}