import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteEnquiry } from '../Redux/action/admissionAction'


export default function EnquiryConfirm({newdata}) {
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch()

  return (
    
    <div className="modal fade" id="enquiryconfirm"  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Delete Enquiry</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
        <span>Full Name : {newdata.fullname}</span><br/>
          <span>Mobile Number : {newdata.number}</span><br/>
          <span>Course : {newdata.course}</span><br/>
          <span>Email : {newdata.email}</span><br/>
          
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button 
          onClick={()=>{dispatch(DeleteEnquiry(newdata._id, auth))}}
           data-bs-dismiss="modal" aria-label="Close" type="button" className="btn btn-danger">Delete</button>
        </div>
			</div>
      </div>
    </div>
  </div>
    
  )
}
