
import React, {useEffect,useState} from 'react';
import { Link, useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';


import { useDispatch, useSelector } from 'react-redux';

import EnquiryModal from './enquiryModal';
import { DeleteEnquiry, getAdmission } from '../Redux/action/admissionAction';
import EnquiryConfirm from './enquiryConfirm';





const Enquiry = () => {
    const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();
  const [newdata, setnewdata] = useState([]);
  
  const data = useSelector((store) => store.admissionReducer.data);
  const auth = useSelector((state) => state.authReducer);

	const getEnquery = () => {
		setLoading(true)
		dispatch(getAdmission(auth)).then((res) => {
			setLoading(false)
		}).catch(err => {
			console.log(err)
			setLoading(false)
		});
	}
 
	useEffect(() => {	
		getEnquery()

	},[dispatch, newdata]);


	
	// 	let res = addmission.filter((item)=>{
	// 		return item.fullname.toLowerCase().match(search.toLowerCase())
	// 	})
	// 	setdata(res)
	
    const columns = [
            { name:"Id",
	            selector : (row) => `${row._id}`.substring(0, 6), sortable : true,
            },
            { name:"Status",
            selector : (row) => row.status,
            sortable : true,
            },
            { name:"Full Name",
            selector : (row) => row.fullname,
            sortable : true,
            },
            { name:"Date",
            selector : (row) => `${row.date}`.substring(0, 10),
            sortable : true,
            },
            { name:"Course",
            selector : (row) => row.course,
            sortable : true,
            },
			{ name:"Email",
            selector : (row) => row.email,
            sortable : true,
            },
            { name:"Phone No.",
            selector : (row) => row.number,
            sortable : true,
            },
            {
                name:"Call Date",
                selector: (row) => `${row.calldate}`.substring(0, 10),
                sortable : true,
            },
            {
                name:"Call Remark",
                selector: (row) => row.callremark,
                sortable : true,
            },
            {
                name:"Status Date",
                selector: (row) => row.statusdate,
                sortable : true,
            },
            {
                name:"Status Remark",
                selector: (row) => row.statusremark,
                sortable : true,

            },
            {
                name:"Action",
                cell : (row) => <div className="actions">
                <button className="btn btn-sm bg-success-light ms-2" >
                    <i onClick={(e)=>{setnewdata(row)}} className="fas fa-pen" data-bs-toggle="modal" data-bs-target="#enquirymodal"></i>
                </button>
                <a  onClick={(e)=>{setnewdata(row)}}  className="btn btn-sm bg-danger-light" data-bs-toggle="modal" data-bs-target="#enquiryconfirm">
                    <i className="fas fa-trash"></i>
                </a>
            </div>,
            }
    ]



    return (
	<>
		{/* <!-- Main Wrapper --> */}
		<div >
            <EnquiryModal newdata={newdata} setnewdata={setnewdata} />
			<EnquiryConfirm newdata={newdata}  />

			{/* <!-- Page Wrapper --> */}
			<div className="page-wrapper">
                <div className="content container-fluid">
				
					{/* <!-- Page Header --> */}
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Students</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
									<li className="breadcrumb-item active">Students</li>
								</ul>
							</div>
							<div className="col-auto text-end float-end me-auto">
								<a href="#" className="btn btn-outline-primary ms-2"><i className="fas fa-download"></i> Download</a>
								<Link to="/addstudents" className="btn btn-primary"><i className="fas fa-plus"></i></Link>
							</div>
						</div>
					</div>
					{/* <!-- /Page Header --> */}
				
					<div className="row">
						<div className="col-sm-12">
						
							<div className="card card-table">
								<div className="card-body">
									<div className="table-responsive">
										{loading ? <center>
													<div className="spinner-border" role="status">
														<span className="sr-only">Loading...</span>
													</div>  
												</center> :
                                        <DataTable
											title="Student Enquiry"
											columns={columns}
											data={data}
											pagination
											highlightOnHover	
										/>
									}

										{/* <table className="table table-hover table-center mb-0 datatable" id="datatables" >
											<thead>
												<tr>
													<th>ID</th>
													<th>Status</th>
													<th>Full name</th>
													<th>Date</th>
													<th>Course</th>
													<th>Phone no.</th>
													<th>Call Date</th>
													<th>Call Remark</th>
													<th>Status Date</th>
													<th>Student Remark</th>
 													<th className="text-end">Action</th>
												</tr>
											</thead>
											<tbody>
                                                {data?.map((e)=>(
												<tr key={e._id}><td>{`${e._id}`.substring(0, 4)}...</td>
													<td>
														<h2 className="table-avatar">
															<Link to="/studentdetails">{e.status}</Link>
														</h2>
													</td>
													<td>{e.fullname}</td>
													<td>{new Date(e.date).toString().substring(3,10)}</td>
													<td>{e.course}</td>
													<td>{e.number}</td>
													<td>{`${e.calldate}`.substring(3, 10)}</td>
													<td>{`${e.callremark}`.substring(0, 20)}...</td>
													<td>{`${e.statusdate}`.substring(3, 10)}</td>
													<td>{`${e.statusremark}`.substring(0, 20)}...</td>
													<td className="text-end">
														<div className="actions">
															<Link to="/editstudents" className="btn btn-sm bg-success-light ms-2">
																<i className="fas fa-pen"></i>
															</Link>
															<a href="#" className="btn btn-sm bg-danger-light">
																<i className="fas fa-trash"></i>
															</a>
														</div>
													</td></tr>
                                                ))}

											</tbody>
										</table> */}
									</div>
								</div>
							</div>							
						</div>					
					</div>					
				</div>

				{/* <!-- Footer --> */}
				<footer>
					<p>Copyright © 2023 Ifastacademy.</p>					
				</footer>
				{/* <!-- /Footer --> */}

			</div>
			{/* <!-- /Page Wrapper --> */}

		</div>
		{/* <!-- /Main Wrapper --> */}

	</>
    );
}

export default Enquiry;
