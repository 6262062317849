import { ADD_COURSE, GET_COURSE_BYID } from "../action/courseAction"


const courseReducer=(store={data:[]},{type,payload})=>{
    switch(type){
     case ADD_COURSE:
         return {...store,data:payload}
         case GET_COURSE_BYID:
         return {...store,data:payload}

         default:
             return store
    }
}

export default courseReducer;
