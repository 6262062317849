
export const GET_ADMISSION="GET_ADMISSION"
export const SORTH="SORTH"
export const SORTL="SORTL"


export const admissionReducer = (store={data:[]},{type,payload})=>{
    switch(type){
     case GET_ADMISSION:
         return {...store,data:payload}

         default:
             return store
    }
}

export default admissionReducer;
 