import axios from "axios"
import { toast } from "react-toastify"


export const ADD_REFERRAL = "ADD_REFERRAL"
export const GET_REFERRALS = "GET_REFERRALS"


export const addreferral=(data)=>{
    return {
        type:ADD_REFERRAL,
        payload:data
    }
}




export const getReferrals=(auth)=>{
    return async(dispatch, getState, api) => {
        
            axios
            .get(`${process.env.API_URL}/refeeral/${auth._id}`)
            .then((res) => {
                dispatch(addreferral(res.data))
            })
            .catch((err) => {
              console.log(err, "here");
            });
       
      }
}

export const getReferralsforAdmin=(auth)=>{
    return async(dispatch, getState, api) => {
        
        axios
        .get(`${process.env.API_URL}/refeeral`)
        .then((res) => {
            dispatch(addreferral(res.data))

        })
        .catch((err) => {
          console.log(err, "here");
        });
       
      }
}

// export const getCourseById=(id)=>{
//     return async(dispatch, getState, api) => {
//          try {
//              let res=await fetch(`${process.env.API_URL}/course/${id}`)
//              let data=await res.json()
//             //  console.log("coursAction",data)
//              dispatch(addcousebyid(data))
//          } 
//          catch (error) {
//             console.log(error)
//          }
//       }
// }



// export const deleteCourse = (id)=>{
//     return async(dispatch, getState, api)=>{
        
//         try {
//             const toastid = toast.loading("Course Deleting..." ,{position: "top-center",})
//             await fetch(`${process.env.API_URL}/course/${id}`,{
//                     method:"DELETE",
                   
//                     headers:{
//                         'Content-Type': 'application/json'
//                     }
//             })
//             toast.update(toastid, {render: "Course deleted successfully", type: "success", isLoading: false,position: "top-center",
//                   autoClose: 2000,
//                   hideProgressBar: true,
//                   closeOnClick: true,
//                   pauseOnHover: true,
//                   draggable: true,
//                   progress: undefined,
//                   theme: "light",
//                 });
                  
//             dispatch(getCourse())
//         } 
//         catch (error) {
//             console.log(error)
//         }
// }
// }

export const PostReferral=(data,auth,updatedata)=>{
    return async(dispatch, getState, api) => {
        try{
            const toastid = toast.loading("Referral Updating adding..." ,{position: "top-center",})
            axios
            .patch(`${process.env.API_URL}/refeeral/${data._id}`,updatedata)
            .then((res) => {
                dispatch(getReferrals(auth))

                toast.update(toastid, {render: "Your funds withdrawal request has been sent successfully. Please meet your instructor to receive your reward or funds. Happy learning and enjoy your rewards!", type: "success", isLoading: false,position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });


            })
            .catch((err) => {
              console.log(err, "here");
            });
          }
          catch(err){
            console.log(err)
                   
          }
     }
}


export const PostReferralPoints=(data,auth,updatedata)=>{
  return async(dispatch, getState, api) => {
      try{
          const toastid = toast.loading("Referral Updating adding..." ,{position: "top-center",})
          axios
          .patch(`${process.env.API_URL}/refeeral/${data._id}`,updatedata)
          .then((res) => {
              dispatch(getReferrals(auth))

              toast.update(toastid, {render: `🎉 Congratulations! 🎉
              You've earned 100 points from this referral! 🌟 Well done! 🌟`, type: "success", isLoading: false,position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });


          })
          .catch((err) => {
            console.log(err, "here");
          });
        }
        catch(err){
          console.log(err)
                 
        }
   }
}



export const PostReferralforAdmin=(data,auth,updatedata)=>{
    return async(dispatch, getState, api) => {
        try{
            const toastid = toast.loading("Referral updating adding..." ,{position: "top-center",})
            axios
            .patch(`${process.env.API_URL}/refeeral/${data._id}`,updatedata)
            .then((res) => {
                dispatch(getReferralsforAdmin(auth))

                toast.update(toastid, {render: "Referral Updatd Successfuly", type: "success", isLoading: false,position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });


            })
            .catch((err) => {
              console.log(err, "here");
            });
          }
          catch(err){
            console.log(err)
                   
          }
     }
}

// export const PatchCourse=(data,id)=>{
//     return async(dispatch, getState, api) => {

//         const toastid = toast.loading("Course updating..." ,{position: "top-center",})
//         try {
            
//             await fetch(`${process.env.API_URL}/course/${id}`,{
//                     method:"PATCH",
//                     body:JSON.stringify(data),
                   
//                     headers:{
//                         'Content-Type': 'application/json'
//                     }
//             })

//             toast.update(toastid, {render: "Course updated successfully", type: "success", isLoading: false,position: "top-center",
//             autoClose: 2000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//         });
//         dispatch(getCourse())
            
//         } 
//         catch (error) {
//             toast.update(toastid, {render: error.response?.data, type: "error", isLoading: false,position: "top-center",
//             autoClose: 2000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//         });
//             console.log(error)
//         }
// }
// }