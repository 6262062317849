
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Link } from 'react-router-dom'
import Header from "../header";
import Sidebar from '../sidebar';

import { useDispatch, useSelector } from 'react-redux';
import { getFees } from '../Redux/action/feesAction';
import DataTable from 'react-data-table-component';
import FeesModal from './feesModal';



const Feescollection = () => {

	const dispatch = useDispatch()
	const data = useSelector((state)=> state.feesReducer.data)
	const auth = useSelector((state) => state.authReducer);

	const [status ,setstatus] = useState([])
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	
	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);
	
	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	
	const filteredItems = data.filter(
		item => item.student_id.toLowerCase().includes(filterText.toLowerCase()) || item.full_name.toLowerCase().includes(filterText.toLowerCase()),
	);

	const handlefilter = (e) => {		
		setFilterText(e.target.value);
	}
	
	const subHeaderComponentMemo = useMemo(() => {
		return (

			<div className="input-group w-50">
				<input id="search-input" type="search" className="form-control" placeholder="Search Name Or ID" onChange={(e)=>handlefilter(e)}  />  
				<button id="search-button" type="button" className="btn btn-primary">
				<i className="fa fa-search"></i>
				</button>
				
			</div> 

			
			
		);
	}, [filterText, resetPaginationToggle]);




	const contextActions = useMemo(() => {
		const handleDelete = () => {
			
			if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.full_name)}?`)) {
				setToggleCleared(!toggleCleared);
				
			}
		};

		return (
			<button key="delete" className='btn btn-danger' onClick={handleDelete} >
				Delete
			</button>
		);
	}, [data, selectedRows, toggleCleared]);
	

    useEffect(() => {
		dispatch(getFees(auth))
				
	},[]);

	const handlestatus =(row)=>{
		
		
		setstatus(row)
				
		}
		
	const columns = [
		{ name:"Full Name",
		 selector : (row) => row.full_name,

		 sortable : true,
		 },
		 
		 { name:"Studnet ID",
		 selector : (row) => row.student_id,
		 sortable : true,
		},
	
		 { name: "Pay To",
		  selector: (row) => row.pay_to,
		sortable: true },

		{ name: "Teacher",
		 selector: (row) => row.teacher,
	 	 sortable: true },

		 { name:"Date",
		 selector : (row) => `${row.date}`.substring(0, 10),
		 sortable : true,
		 },

		 { name:"Fee Amount",
		 selector : (row) => row.fees_amount,
		 sortable : true,
		 },

		 { name:"Fee Month",
		 selector : (row) => `${(new Date(row.fees_month)).toString().substring(3,8)} ${(new Date(row.fees_month)).toString().substring(10, 15)}`,
		 sortable : true,
		 },
		 { name: "Accept By", 
		 selector: (row) => row.accept_by, 
		 sortable: true },

		 { name:"Status",
		 selector : (row) => <div  className="text-end" >
								{row.status ? (
								<span className="badge badge-success">paid</span>
								) :(
								<span className="badge badge-warning">pending</span>
								)}
							  </div>,
		 sortable : true,
		 },

		 {
			name:"Action",
			cell : (row) => <div className="actions">
			<button onClick={(e)=>{handlestatus(row)}} data-bs-toggle="modal" data-bs-target="#feesmodal" className="btn btn-sm bg-success-light ms-2" >
				<i  className="fas fa-pen" ></i>
			</button>
			{/* <a href="#" className="btn btn-sm bg-danger-light">
				<i className="fas fa-trash"></i>
			</a> */}
		</div>,
		},
		
 ]


    return (
<>
		{/* <!-- Main Wrapper --> */}
		<div>
			
			<FeesModal status={status} setstatus={setstatus} />

			{/* <!-- Page Wrapper --> */}
			<div className="page-wrapper">
                <div className="content container-fluid">
					 
					{/* <!-- Page Header --> */}
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Fees Accept</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
									<li className="breadcrumb-item active">Fees Accept</li>
								</ul>
							</div>
							<div className="col-auto text-end float-left mr-auto">
								<a href="#" className="btn btn-outline-primary ms-2"><i className="fas fa-download"></i> Download</a>
								<Link to="/add-fees-collection" className="btn btn-primary"><i className="fas fa-plus"></i></Link>
							</div>
						</div>
					</div>
					{/* <!-- /Page Header --> */}
				
					<div className="row">
						<div className="col-sm-12">
						
							<div className="card card-table">
								<div className="card-body">
									<div className="table-responsive">

									<DataTable
                                         title="Fees Accept"
                                         		columns={columns}
												contextActions={contextActions}
												onSelectedRowsChange={handleRowSelected}
												clearSelectedRows={toggleCleared}
												pagination
												highlightOnHover
												data={filteredItems}
												paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
												subHeader
												subHeaderComponent={subHeaderComponentMemo}
												persistTableHead
												fixedHeader
  												fixedHeaderScrollHeight="400px"
										 
                                         />

										
									</div>
								</div>
							</div>							
						</div>					
					</div>					
				</div>

				{/* <!-- Footer --> */}
				<footer>
					<p>Copyright © 2023 Ifastacademy.</p>					
				</footer>
				{/* <!-- /Footer --> */}

			</div>
			{/* <!-- /Page Wrapper --> */}

		</div>
		{/* <!-- /Main Wrapper --> */}
</>
    );
}

export default Feescollection;
