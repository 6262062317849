import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { postFees } from "../Redux/action/feesAction";
import axios from "axios";
import ReactConfetti from "react-confetti";
import FeesPendingModal from "./feesPendingModal";
import { toast } from "react-toastify";

const Studentsfees = () => {
  const navigate = useNavigate();
  const [wait, isWait] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [findstudentid, setfindstudentid] = useState("");
  const [data, setdata] = useState([]);
  const [status, setstatus] = useState([]);
  const [userdata, setuserdata] = useState([]);

  const confetiRef = useRef(null);
  const [numberOfPieces, setnumberOfPieces] = useState(0);

  const auth = useSelector((state) => state.authReducer);

  const { name, username, email, _id } = auth;

  const [options, setOptions] = useState([
    { id: "Monthly Fee", text: "Monthly Fee" },
    { id: "Admission Fee", text: "Admission Fee" },
    { id: "Re-Registration Fee", text: "Re-Registration Fee" },
    { id: "Certificate Fee", text: "Certificate Fee" },
    { id: "Lost/Damaged ID Card", text: "Lost/Damaged ID Card" },
  ]);

  const [options1, setOptions1] = useState([
    { id: "Shri Ram", text: "Shri Ram" },
    { id: "Vishal Kohli", text: "Vishal Kohli" },
    { id: "Neeraj", text: "Neeraj" },
  ]);

  const dispatch = useDispatch();
  const [formState, setFormState] = useState({});

  function handleChange(e) {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      full_name: userdata.name,
      student_id: userdata.username,
      email: userdata.email,
      userId: userdata._id,
      date: new Date(),
      accept_by: auth.name,
      status: true,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = toast.loading("Fee updating...", { position: "top-center" });
    isWait(true);
    axios.post(`${process.env.API_URL}/fees`, formState, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setdata([...data, res.data])
        toast.update(id, {
          render: "Fee added successfully",
          type: "success",
          isLoading: false,
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setFormState({});
        setnumberOfPieces(500);
        setTimeout(() => {
          isWait(false);
          setnumberOfPieces(0);
        }, 4000);
        handleSearch();
      })
      .catch((err) => {
        toast.update(id, {
          render: err.response?.data,
          type: "error",
          isLoading: false,
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        isWait(false);
        console.log(err);
      });
  };

  const config = {
    angle: "192",
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: "10000",
    stagger: "9",
    width: "10px",
    height: "10px",
    perspective: "605px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const handleSearch = (e) => {
    e.preventDefault()
    setIsLoading(true);
    axios
      .post(
        `${process.env.API_URL}/fees/studentid`,
        { findstudentid },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setdata(res.data.student_id);
        setuserdata(res.data.findstudentdata);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const columns = [
    {
      name: "Full Name",
      selector: (row) => row.full_name,

      sortable: true,
    },

    { name: "Studnet ID", selector: (row) => row.student_id, sortable: true },
    { name: "Pay To", selector: (row) => row.pay_to, sortable: true },
    { name: "Teacher", selector: (row) => row.teacher, sortable: true },

    {
      name: "Date",
      selector: (row) => `${row.date}`.substring(0, 10),
      sortable: true,
    },

    {
      name: "Fee Amount",
      selector: (row) => row.fees_amount,
      sortable: true,
    },

    {
      name: "Fee Month",
      selector: (row) =>
        `${new Date(row.fees_month).toString().substring(3, 8)} ${new Date(
          row.fees_month
        )
          .toString()
          .substring(10, 15)}`,
      sortable: true,
    },
    { name: "Accept By", selector: (row) => row.accept_by, sortable: true },

    {
      name: "Status",
      selector: (row) => (
        <div className="text-end">
          {row.status ? (
            <span className="badge badge-success">paid</span>
          ) : (
            <span className="badge badge-warning">pending</span>
          )}
        </div>
      ),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="actions">
          <button
            onClick={(e) => {
              handlestatus(row);
            }}
            data-bs-toggle="modal"
            data-bs-target="#feespendingmodal"
            className="btn btn-sm bg-success-light ms-2"
          >
            <i className="fas fa-pen"></i>
          </button>
          {/* <a href="#" className="btn btn-sm bg-danger-light">
				<i className="fas fa-trash"></i>
			</a> */}
        </div>
      ),
    },
  ];

  const handlestatus = (row) => {
    setstatus(row);
  };
  return (
    // <!-- Main Wrapper -->
    <div>
      {/* <!-- Page Wrapper --> */}
      <FeesPendingModal status={status} setstatus={setstatus} />

      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <!-- Page Header --> */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Add Fees</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/feeslist">Fees</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Fees</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- /Page Header --> */}

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={(e) => handleSearch(e)} className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Please Enter Student Id</label>
                      <input onChange={(e) => setfindstudentid(e.target.value)} placeholder="Please Enter Student Id" type="text" className="form-control"/>
                    </div>
                    <div className="text-end mb-5">
                      <div>
                        <button
                          // onClick={() => handleSearch()}
                          disabled={isLoading}
                          className={`btn btn-primary ${ isLoading ? " loading" : ""}`}
                          type="submit"
                        >
                          {isLoading ? (
                            <span
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                          {isLoading && (
                            <span className="loading-text">Loading...</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card card-table">
                        <div className="card-body">
                          <div className="table-responsive">
                            <DataTable
                              title="Fees List"
                              columns={columns}
                              data={data}
                              pagination
                              highlightOnHover
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="confettie-wrap" ref={confetiRef}>
                    <ReactConfetti
                      numberOfPieces={numberOfPieces}
                      config={config}
                    />
                  </div>
                  {userdata?.username?.length > 0 ? (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <h5 className="form-title">
                            <span>Fees Information</span>
                          </h5>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label>Student Id</label>
                            <input
                              disabled
                              type="text"
                              value={userdata?.username || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label>Full Name</label>
                            <input
                              disabled
                              type="text"
                              value={userdata.name || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label>Fee Type</label>
                            <Select2
                              required
                              value={formState.fees_type || ""}
                              onChange={handleChange}
                              className="w-100"
                              name="fees_type"
                              data={options}
                              options={{
                                placeholder: "Select Type",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label>Teacher</label>
                            <Select2
                              required
                              value={formState.teacher || ""}
                              onChange={handleChange}
                              className="w-100"
                              name="teacher"
                              data={options1}
                              options={{
                                placeholder: "Select Teacher",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label>Pay to</label>
                            <Select2
                              required
                              value={formState.pay_to || ""}
                              onChange={handleChange}
                              className="w-100"
                              name="pay_to"
                              data={options1}
                              options={{
                                placeholder: "Select Teacher",
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label>Fee Amount</label>
                            <input
                              onChange={handleChange}
                              value={formState.fees_amount || ""}
                              name="fees_amount"
                              type="number"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label>Fee Month</label>
                            <input
                              onChange={handleChange}
                              value={formState.fees_month || ""}
                              name="fees_month"
                              type="month"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <button
                            type="submit"
                            disabled={wait}
                            className="btn btn-primary"
                          >
                            {" "}
                            {wait ? "Please Wait..." : "Submit"}{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Page Wrapper --> */}
    </div>
    // <!-- /Main Wrapper -->
  );
};

export default Studentsfees;
