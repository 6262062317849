import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postAdmission } from "../Redux/action/admissionAction";
import admissionimg from "../assets/img/admission.png";
import admissionback from "../assets/img/admissionback.png";
import { useEffect } from "react";
import { getCourse } from "../Redux/action/courseAction";
import Confetti from "react-confetti";
import { toast } from "react-toastify";
import axios from "axios";

export default function Admission() {
  const dispatch = useDispatch();
	const data = useSelector((state)=>state.courseReducer.data) 
  const [message, setmessage] = useState({})
  const [isLoading, setIsLoading] = useState(false);


  const [formState, setFormState] = useState({});

  function handleChange(e) {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      date:new Date().toDateString()
    });
  }

  useEffect(()=>{
	dispatch(getCourse())

  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
          
          axios.post(`${process.env.API_URL}/admission`,formState )
          .then((res) => {
              setmessage(res.data)
              if(res.data.success){
                setnumberOfPieces(500)
                setTimeout(() => {

                window.location.href = 'https://www.ifastacademy.com/';
                setIsLoading(false);
              },3000);
            }
          

          })
          .catch((error) => {
          
              console.log(error)
              
          });

    setTimeout(() => {
      setmessage({})
      setIsLoading(false);
    }, 3000);


    
  };



  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const confetiRef = useRef(null);
  const [numberOfPieces , setnumberOfPieces] = useState(0)

  useEffect(() => {
    setHeight(confetiRef.current.clientHeight);
    setWidth(confetiRef.current.clientWidth);
  }, []);


  const config = {
    angle: "192",
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: "10000",
    stagger: "9",
    width: "10px",
    height: "10px",
    perspective: "605px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };
  return (
    <div className="signUp-page mt-5 ">
      <div className="confettie-wrap" ref={confetiRef}>
        <Confetti numberOfPieces={numberOfPieces} config={config} />
      </div>
      <div className="container   ">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 ">
            <div className="bg-white shadow rounded ">
              <div className="row ">
                <div className="col-md-5 ps-0 d-none  d-md-block flex bg-primary">
                  <div className="form-right h-100 bg-primary text-white text-center    pt-5">
                    <img
                      src={admissionback}
                      alt=""
                      style={{
                        position: "absolute",
                        height: "200px",
                        width: "200px",
                      }}
                    />
                    <img
                      src={admissionimg}
                      style={{
                        width: "500px",
                        height: "100%",
                      }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-7 pe-0 adminssiondiv">
                  <div className="form-left h-100 py-5 px-5">
                  {isLoading && message.message!="" ? <><div className={`alert alert-${message.success ? 'success' : 'danger'}`} role="alert">
                        <strong>Meaage!</strong> {message.message}
                      </div></>: ""}
                    <form  onSubmit={handleSubmit} className="form_input" >
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Full Name</label>
                        <div className="col-lg-9">
                          <input
                            onChange={(e)=>handleChange(e)}
                            type="text"
                            name="fullname"
                            value={formState.fullname || ''}
                            className="form-control"
                            required
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Mobile No</label>
                        <div className="col-lg-9">
                          <input
                            onChange={(e)=>handleChange(e)}
                            type="number"
                            name="number"
                            className="form-control"
                            placeholder="Phone Number"
                            value={formState.number || ''}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Email</label>
                        <div className="col-lg-9">
                          <input
                            onChange={(e)=>handleChange(e)}
                            name="email"
                            type="email"
                            className="form-control"
                            value={formState.email || ''}
                            placeholder="Email Address (optional)"
                            
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Qualification</label>
                        <span className="col-lg-9">
                          <select
                            onChange={(e)=>handleChange(e)}
                            className="form-select"
                            aria-label="Default select example"
                            value={formState.qualification || ''}
							              name="qualification"
                            required
                          >
                            <option value='' defaultValue={"Select"} >
                              Select Qualification
                            </option>
                            <option value='Below 10th class'>Below 10th class</option>
                            <option value="Below 12th class">Below 12th class</option>
                            <option value="Undergraduate">Undergraduate</option>
                            <option value="Postgraduate">Postgraduate</option>
                            <option value="Other">Other</option>
                          </select>
                        </span>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Occupation</label>
                        <span className="col-lg-9">
                          <select
                            onChange={(e)=>handleChange(e)}
                            className="form-select"
                            aria-label="Default select example"
                            value={formState.occupation || ''}
							              name="occupation"
                            required
                          >
                            <option value='' defaultValue={"Select"}>
                              Select Occupation
                            </option>
                            <option value="Student">Student</option>
                            <option value="Self Employee">Self Employee</option>
                            <option value="Freelancer">Freelancer</option>
                            <option value="Salaried">Salaried</option>
                            <option value="Unemployee">Unemployee</option>
                            
                          </select>
                        </span>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Coures</label>
                        <span className="col-lg-9">
                          <select
                            onChange={(e)=>handleChange(e)}
                            className="form-select"
                            aria-label="Default select example"
                            value={formState.course || ''}
							              name="course"
                            required
                          >
                            <option value='' defaultValue={"Select"}>
                              Select Course
                            </option>
							            {data?.map((e)=>(

                          <option key={e._id} value={e.course}>{e.course}</option>
                          ))}
                                      
                          </select>
                        </span>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Referral Code</label>
                        <div className="col-lg-9">
                          <input
                            onChange={(e)=>handleChange(e)}
                            type="text"
                            name="refeeralcode"
                            className="form-control"
                            placeholder="Refeeral code(optional)"
                            value={formState.refeeralcode || ''}
                          />
                        </div>
                      </div>

                      <div className="text-end">
                      <div>
                            <button
                            disabled={isLoading}
                              className={`btn btn-primary${isLoading ? ' loading' : ''}`}
                              type="submit"
                            >
                              {isLoading ? (
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                'Submit'
                              )}
                              {isLoading && <span className="loading-text">Loading...</span>}
                            </button>
                          </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      );
}
