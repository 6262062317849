import React from 'react'
import coin from "../../assets/img/gifts/coin.png";
import { useDispatch, useSelector } from 'react-redux';
import { PostReferral, PostReferralPoints } from '../../Redux/action/referralsAction';

export default function PointsModal({withdrawaldata,setwithdrawaldata}) {

  const auth = useSelector((state) => state.authReducer);
    
  const dispatch = useDispatch()

  const handleSubmit = (withdrawaldata) =>{


    console.log(withdrawaldata, "newdata")

      dispatch(PostReferralPoints(withdrawaldata,auth, {refeeralstatus:"points", points:'100'}))
      
     
  } 

  return (
    <div className="modal fade" id="pointsmodal" aria-labelledby="pointsmodal" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="pointsmodal">Points</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <img src={coin} alt="" />
        Please confirm your intention to receive the points. Once you proceed, you will be credited with 100 points.
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(e)=>handleSubmit(withdrawaldata)} >Proceed</button>
        </div>
      </div>
    </div>
  </div>
  )
}
