import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ChangeName } from '../Redux/action/userAction';

export default function ChangeNameModal() {

const [FormState,setFormState] = useState()
const auth = useSelector((state) => state.authReducer);
const dispatch = useDispatch();
  function handleChange(e) {
    const { name, value } = e.target;
    setFormState({
      ...FormState,
      [name]: value,
      ['_id']:auth._id,
    });
  }

  const handlesubmit = (e) =>{
   
    if(FormState==undefined){
      toast.error('Name must be more then 3 characters long', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    else{

      dispatch(ChangeName(FormState))
      
    }
   
    
}


  return (
    
    <div className="modal fade" id="changename"  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Update user</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
       
              <div className="col-lg-12">
                <label>Update Name</label>
                <div className="input-group">
                  <input type="text"
                  onChange={(e)=>handleChange(e)}
                  name="name"
                  className="form-control"
                  placeholder="Full Name"
                  
                  />
                </div>
              </div>
              
              <button onClick={()=>handlesubmit()} data-bs-dismiss="modal" aria-label="Close" className="btn btn-primary mt-5" >
                Save Changes
              </button>
            
       
			</div>
      </div>
    </div>
  </div>
  )
}
