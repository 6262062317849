export const  GET_STUDENTS="GET_STUDENTS"
export const FIND_USER = "FIND_USER"
import axios  from "axios"
 import { toast } from 'react-toastify';
import { getAdmission } from "./admissionAction";
// import { setHeaders } from "../../api"

export const studentdata=(data)=>{
    return {
        type:GET_STUDENTS,
        payload:data
    }
}

export const finduser=(data)=>{
  return {
      type:FIND_USER,
      payload:data
  }
}





export const getStudents=(auth)=>{
    return async(dispatch, getState, api) => {
         try {  
             let res=await fetch(`${process.env.API_URL}/students`,{
              headers: {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
              },
             })
             let data=await res.json()
           
             dispatch(studentdata(data))
         } 
         catch(error){
            console.log(error)
         }
      }
}



export const FindUser= (auth)=>{

  return async(dispatch)=>{

    try{
      axios.post(`${process.env.API_URL}/students/findstudent`,auth,{
        headers: {
          "Authorization": `Bearer ${auth.token}`,
          "Content-Type": "application/json"
        },
      })
      .then((res)=>{
        dispatch(finduser(res.data))
      })

  }
    catch(err){
      console.log(err.response)
    }
    
}}




export const postStudents = (data, auth) => {

  return async (dispatch) => { 
    const id = toast.loading("Profile updating..." ,{position: "top-center",})
    try {
          await axios
            .post(`${process.env.API_URL}/students/${auth._id}`, data,{
              headers: {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
              },
            })
            .then((e) => {

              toast.update(id, {render: "Profile updated", type: "success", isLoading: false,position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",});
              dispatch(FindUser(auth));
          
            })

            .catch((error) => {
              toast.update(id, {render: error, type: "success", isLoading: false,position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",});
            });
        } catch (err) {
          console.log(err);
        }
      };
    };


    export const postStudentsprofile = (data, auth) => {

      return async (dispatch) => { 
        const id = toast.loading("Profile updating..." ,{position: "top-center",})
        try {
              await axios
                .post(`${process.env.API_URL}/students/profileupdate/${auth._id}`, data,{
                  headers: {
                    "Authorization": `Bearer ${auth.token}`,
                    "Content-Type": "application/json"
                  },
                })
                .then((e) => {
    
                  toast.update(id, {render: "Profile updated", type: "success", isLoading: false,position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",});
                  dispatch(FindUser(auth));
              
                })
    
                .catch((error) => {
                  toast.update(id, {render: error, type: "success", isLoading: false,position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",});
                });
            } catch (err) {
              console.log(err);
            }
          };
        };
    

    export const postStudentsProfile = (data, id) => {
      // console.log(data)
      const formdata = new FormData();
      
      formdata.append('backgroundimg', data.backgroundimg)

      return async (dispatch) => {  
          try {
            await axios
              .post(`${process.env.API_URL}/students/profile/${id}`, formdata)
              .then((e) => {
                dispatch(FindUser());
              })
              .catch((error) => {
                alert(error.response?.data);
              });
          } catch (err) {
            console.log(err);
          }
        };
      };
  

    export const PatchAdmission=(data,id,auth)=>{
        return async(dispatch, getState, api) => {
        const idtoast = toast.loading("Enquiry updating..." ,{position: "top-center",})

            try {
                await fetch(`${process.env.API_URL}/admission/${id}`,{
                        method:"PATCH",
                        body:JSON.stringify(data),
                        headers: {
                          "Authorization": `Bearer ${auth.token}`,
                          "Content-Type": "application/json"
                        },
                    })
                    toast.update(idtoast, {render: "Enquery Update Successfully", type: "success", isLoading: false,position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                  
              
                        })

                    dispatch(getAdmission(auth))
                
            } 
            catch (error) {
              toast.success(error, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
    }
    }




    export const postStudentStatus = (data, auth) => {

      return async (dispatch) => { 
        const id = toast.loading("Student information updating..." ,{position: "top-center",})
        try {
              await axios
                .post(`${process.env.API_URL}/studentstatus`,data,{
                  headers: {
                    "Authorization": `Bearer ${auth.token}`,
                    "Content-Type": "application/json"
                  },
                })
                .then((e) => {
                  toast.update(id, {render: "Information updated", type: "success", isLoading: false,position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",});
                  
              
                })
    
                .catch((error) => {
                  toast.update(id, {render: error.response.data, type: "error", isLoading: false,position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",});
                });
            } catch (err) {
              console.log(err);
            }
          };
        };
    