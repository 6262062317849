import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import scribble from '../assets/img/scribble.png'

import axios from "axios";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import emoji1 from "../assets/emoji/happy.png"
import emoji2 from '../assets/emoji/hypnotized.png'
const Attendance = (props) => {
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch()

 
  const [currentEvents, setscurrentEvents] = useState();
  const [percentage, setpercentage] = useState();
  const defaultEvents = [];

  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("width-100"));
  }, []);

  useEffect(() => {
    var decode = jwtDecode(auth.token);
    axios
      .post(`${process.env.API_URL}/attendance`, decode)
      .then((res) => {
        setscurrentEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });


      axios
      .post(`${process.env.API_URL}/attendance/percentage`, decode)
      .then((res) => {
        setpercentage(res.data);
      })
      .catch((err) => {
        console.log(err);
      });




  }, []);

  

  const columns = [
    {
        name: 'Months',
        selector: row => <div>{row.month} {row.year}</div> 
    },
    {
        name: 'Total Classes',
        selector: row => <div>{row.total_class}</div>,
    },
    {
      name: 'Present',
      selector: row =>  <div>{row.present} <div>{Math.floor((row.present/row.total_class)*100)}%</div></div>,
    },
    {
      name: 'Absent',
      selector: row =>  <div>{row.absent} <div>{Math.floor((row.absent/row.total_class)*100)}%</div></div>,
    },
    {
      name: 'Late',
      selector: row => <div>{row.late}  <div> {Math.floor((row.late/row.total_class)*100)}%</div></div>,
    },
    {
      name: 'Status',
      selector: row => <div><img className="w-25" src={Math.floor((row.present/row.total_class)*100) > 75 ? `${emoji1}` : `${emoji2}`} alt="" /></div>,
    },
    
];


const conditionalRowStyles = [
  {
    when: row => Math.floor((row.present/row.total_class)*100) < 75,
    style: {
      // backgroundColor: '#F9E0D6',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  {
    when: row => Math.floor((row.present/row.total_class)*100) > 75,
    style: {
      // backgroundColor: '#D4EFDF',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  // You can also pass a callback to style for additional customization
  // {
  //   when: row => Math.floor((row.present/row.total_class)*100) < 75,
  //   style: row => ({
  //     backgroundColor: row.phone.startsWith('9') || row.phone.startsWith('1') ? 'pink' : 'inerit',
  //   }),
  // },
];



  
 

  return (
    // <!-- Main Wrapper -->
    <div className="main-wrapper">
      {/* <!-- Page Wrapper --> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Attendance Status</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/studentdashboard">Student Dashboard</Link></li>
									<li className="breadcrumb-item active">Attendance</li>
								</ul>
							</div>
						</div>
					</div>	
          {/* <!-- /Page Header --> */}

          {/* <!-- Overview Section --> */}
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-nine w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-icon">
                      <i className="fas fa-book-open"></i>
                    </div>
                    <div className="db-info">
                      <h3>04/06</h3>
                      <h6>All Courses</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-six w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-icon">
                      <i className="fas fa-file-alt"></i>
                    </div>
                    <div className="db-info">
                      <h3>40/60</h3>
                      <h6>All Projects</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-ten w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-icon">
                      <i className="fas fa-clipboard-list"></i>
                    </div>
                    <div className="db-info">
                      <h3>30/50</h3>
                      <h6>Test Attended</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-eleven w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-icon">
                      <i className="fas fa-clipboard-check"></i>
                    </div>
                    <div className="db-info">
                      <h3>15/20</h3>
                      <h6>Test Passed</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content container-fluid">
            {/* Page Header */}
           
            {/*  /Page Header */}


            <DataTable
                      title="Student Enquiry"
                      columns={columns}
                      data={percentage}
                      conditionalRowStyles={conditionalRowStyles}
                     highlightOnHover
										 

                                         />

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div id="calendar"></div>
                    <FullCalendar
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "",
                      }}
                      initialView="dayGridMonth"
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      dayMaxEvents={true}
                      initialEvents={defaultEvents} // alternatively, use the `events` setting to fetch from a feed
                      events={currentEvents}
                      // eventContent={renderEventContent} // custom render function
                     
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Footer --> */}
        <footer>
          <p>Copyright © 2023 Ifastacademy.</p>
        </footer>
        {/* <!-- /Footer --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </div>
    // <!-- /Main Wrapper -->
  );
};

export default Attendance;
