import React, { useEffect } from 'react'
import { useState } from 'react'
import Avatar from 'react-avatar-edit'
import { useDispatch } from 'react-redux'
import {postStudentsprofile } from '../Redux/action/studentAction'
import axios  from 'axios'
export default function ProfileModal({data, setData, auth}) {

      
//   const handleClose = () => setOpen(false);
  const [src, setsrc] = useState(null)
  const [src2, setsrc2] = useState('')
  const [preview , setpreview] = useState(null)
  const [avatar, setavatar] = useState()
  const [status, setstatus] = useState(false)
  const [srcstatus, setsrcstatus] = useState(false)

  const onCloseSrc = () =>{
        setpreview(null);
  }

useEffect(()=>{
    axios.get(`${process.env.API_URL}/users/avatars`)
    .then((res)=>{
      setavatar(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
},[])


const handlesrc = (e)=>{
  setsrcstatus(true)
  setsrc2(e)
  setData({
    ...data,
    userimg:e

})
}




  const onCrop = (view)=>{
    // console.log(view)

    setpreview(view);
    setData({
        ...data,
        userimg:view
    })}
  const dispatch = useDispatch()

  return (
    
    <div className="modal fade" id="profilemodal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Profile Pic</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>setsrcstatus(false)} aria-label="Close"></button>
        </div>
        <div className="modal-body ">
          <div className='main_avatar'>
          {srcstatus ? (<div> <img  src={src2} size="1rem"  className="rounded-circle"/></div>):( <Avatar 
                width={300}
                height={200}  
                src={src}
                onCloseSrc={onCloseSrc}
                onCrop={onCrop}
                
            />)}
            
        </div>
        {status ? (<div></div>):(<div className='avatar_div'>
              {avatar?.map((e,index)=>(
               
                <div key={e._id}  onClick={()=>handlesrc(e.avatar)}>
                <img  src={e.avatar} size="1rem"  className="rounded-circle"/>
                </div>
              )).slice(0,4)}
           
            </div>)}
        {status ? ( <div className='avatar_div'>
              {avatar?.map((e,index)=>(
                <div key={e._id} onClick={()=>{
                                                handlesrc(e.avatar)
                                                setstatus(curr => !curr)
                                                }} >
                <img  src={e.avatar} size="1rem"  className="rounded-circle"/>
                </div>
              ))}
           
            </div>):(<div></div>)}
           
        </div>
        <div className='avatar_show_more' onClick={()=>setstatus(curr => !curr)}>Show More...</div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={()=>setsrcstatus(false)} data-bs-dismiss="modal">Close</button>
          <button data-bs-dismiss="modal" aria-label="Close" type="button" onClick={()=>{
                                              dispatch(postStudentsprofile(data,auth))
                                              setsrcstatus(false)
                                              
                                              }} className="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
    
  )
}
