

import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom'
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { deleteCourse, getCourse } from '../Redux/action/courseAction';
import CourseModal from './courseModal';




const Course = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const data = useSelector((state)=>state.courseReducer.data)
	const [Course, SetCourse] = useState([])

	// console.log(data)

	useEffect(() => {
		
		dispatch(getCourse())

				
	},[dispatch]);

	const columns = [
		{ name: "Course", selector: (row) => row.course, sortable: true },
	
		{ name: "Fee", selector: (row) => row.coursefees, sortable: true },
		{ name: "Duration", selector: (row) => row.duration, sortable: true },
		{ name: "Programs", selector: (row) => row.programs, sortable: true },
		{
		  name: "Date",
		  selector: (row) => `${row.date}`.substring(0, 10),
		  sortable: true,
		},
		{
			name:"Action",
			cell : (row) => <div className="actions" >
			<button data-bs-toggle="modal"
                    data-bs-target="#coursemodal" onClick={(e)=>SetCourse(row)}
			  className="btn btn-sm bg-success-light ms-2" >
				<i  className="fas fa-pen" ></i>
			</button>
			
			<a onClick={(e)=>dispatch(deleteCourse(row._id))} className="btn btn-sm bg-danger-light">
				<i className="fas fa-trash"></i>
			</a>
			
		</div>,
		},
	
		
	  ];

	  
	
    return (
	<>
		{/* <!-- Main Wrapper --> */}
		<div >
			
			<CourseModal  Course={Course} SetCourse={SetCourse} />
			{/* <!-- Page Wrapper --> */}
			<div className="page-wrapper">
                <div className="content container-fluid">
				
					{/* <!-- Page Header --> */}
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Courses</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
									<li className="breadcrumb-item active">Courses</li>
								</ul>
							</div>
							<div className="col-auto text-end float-end me-auto">
								{/* <a href="#" className="btn btn-outline-primary ms-2"><i className="fas fa-download"></i> Download</a> */}
								<Link to="/addcourse" className="btn btn-primary"><i className="fas fa-plus"></i></Link>
							</div>
						</div>
					</div>
					{/* <!-- /Page Header --> */}
				
					<div className="row">
						<div className="col-sm-12">
						
							<div className="card card-table">
								<div className="card-body">
									<div className="table-responsive">
									<DataTable
                        title="Course List"
                        columns={columns}
                        data={data}
                        pagination
                        highlightOnHover
                      />
										
									</div>
								</div>
							</div>							
						</div>					
					</div>					
				</div>

				{/* <!-- Footer --> */}
				<footer>
					<p>Copyright © 2023 Ifastacademy.</p>					
				</footer>
				{/* <!-- /Footer --> */}
				
			</div>
			{/* <!-- /Page Wrapper --> */}

		</div>
		{/* <!-- /Main Wrapper --> */}
		
	</>
    );
}

export default Course ;
