
export const GET_CERTIFICATEIMG="GET_CERTIFICATEIMG"
export const SORTH="SORTH"
export const SORTL="SORTL"


export const certificateimgRerducer = (store={data:[]},{type,payload})=>{
    switch(type){
     case GET_CERTIFICATEIMG:
         return {...store,data:payload}

     case SORTH:
         return {...store,data:[...store.data.sort((a,b)=>{
             return b.population-a.population        
         })]}

    case SORTL:
        return {...store,data:[...store.data.sort((a,b)=>{
            return a.population-b.population
        })]}
         default:
             return store
    }
}

export default certificateimgRerducer;
 