
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';



const cookies = new Cookies();

// console.log(cookies.get('ifastacademy-token'))
const token  = cookies.get('ifastacademy-token')

const initialState = {
  token: token,
  name: null,
  role: null,
  email: null,
  _id: null,
  username: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGN_IN":
    case "SIGN_UP":
    case "USER_LOADED":
      
      const user = jwtDecode(action.token); 

      
      return {
        ...initialState,
        token: action.token,
        name: user.name,
        username:user.username,
        email: user.email,
        role:user.role,
        _id: user._id,
      }; 
    case "SIGN_OUT":
      cookies.remove("ifastacademy-token");
      toast.success('goodbye...', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      
    
      return {
        token: null,
        name: null,
        username:null,
        email: null,
        _id: null,
        role: null,
      };
    default:
      return state;
  }
};

export default authReducer;
