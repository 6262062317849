import React from 'react'
import { formatDate } from '../../core/DateFormater';

export const Details = ({ data }) => {
    const user = data && data?.userData;
    // console.log(data)
    const img = data?.studentData && data.studentData?.userimg;

    return (
        <>
            <div className="offcanvas offcanvas-start " id="demo">
                <div className="offcanvas-header">
                    <h4 className="offcanvas-title">{user?.name}</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container p-3 d-flex flex-column align-items-center justify-content-center">
                        <img style={{ height: '180px', width: '180px' }} className='img-fluid rounded-circle' src={img || "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"} alt="" />
                        <h4 className='mt-2'>{user?.username}</h4>
                        <p style={{fontSize: '12px'}}>{user?.email}</p>
                        <span className={`badge ${data?.status == `Active` ? 'badge-success'
                                : data?.status == 'Deactive' ? 'badge-danger'
                                    : data?.status == 'Dropout' ? 'badge-danger'
                                        : data?.status == 'Completed' ? 'badge-light'
                                            : data?.status == 'Certified' ? 'badge-info'
                                                : 'badge-warning'} `}>
                            {data?.status}
                        </span>
                    </div>

                    <div className="container p-2 mt-3 border">
                        {/* Data  */}
                        {/* <div className='d-flex justify-content-between align-items-center'>
                            <p>Name</p>
                            <p>{user?.name}</p>
                        </div> */}
                        <div className='d-flex justify-content-between align-items-center'>
                            <p>Father's Name</p>
                            <p>{data?.studentData?.student_father_name || "Not Updated"}</p>
                        </div>
                        {/* <div className='d-flex justify-content-between align-items-center'>
                            <p>Role</p>
                            <p>{user?.role}</p>
                        </div> */}
                        <div className='d-flex justify-content-between align-items-center'>
                            <p>ID Card No</p>
                            <p className={data?.id_card_no ? `text-success` : `text-danger`}>{data?.id_card_no || "Don't Given ID Card No"}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p>Section</p>
                            <p>{data?.section}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p>Batch Time</p>
                            <p>{data?.batch_time}</p>
                        </div>
                        
                        <div className='d-flex justify-content-between align-items-center'>
                            <p>Joining Date</p>
                            <p className={data?.id_card_no ? `text-success` : `text-danger`}>{formatDate(data?.studentData?.date_of_joining)}</p>
                        </div>
                        {/* Data */}
                    </div>
                    {data?.remark &&
                        <div className="container mt-1 border">
                            <p>{data?.remark}</p>
                        </div>
                    }
                </div>
            </div>

            {/* <div className="container-fluid mt-3">
            <h3>Offcanvas Sidebar</h3>
            <p>Offcanvas is similar to modals, except that it is often used as a sidebar.</p>
            <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#demo">
                Open Offcanvas Sidebar
            </button>
        </div> */}
        </>
    )
}


