import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { useDispatch, useSelector } from "react-redux";
import { postFees } from "../Redux/action/feesAction";
import axios from "axios";
import ReactConfetti from "react-confetti";

const Addfees = () => {
  const navigate = useNavigate();
  const [wait, isWait] = useState(false);

  const confetiRef = useRef(null);
  const [numberOfPieces, setnumberOfPieces] = useState(0);

  const auth = useSelector((state) => state.authReducer);

  const { name, username, email, _id } = auth;

  const [options, setOptions] = useState([
    { id: "Monthly Fee", text: "Monthly Fee" },
    { id: "Admission Fee", text: "Admission Fee" },
    { id: "Re-Registration Fee", text: "Re-Registration Fee" },
    { id: "Certificate Fee", text: "Certificate Fee" },
    { id: "Lost/Damaged ID Card", text: "Lost/Damaged ID Card" },
  ]);

  const [options1, setOptions1] = useState([
    { id: "Shri Ram", text: "Shri Ram" },
    { id: "Vishal Kohli", text: "Vishal Kohli" },
    { id: "Neeraj", text: "Neeraj" },
  ]);

  const dispatch = useDispatch();

  const [formState, setFormState] = useState({});

  function handleChange(e) {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      full_name: auth.name,
      student_id: username,
      email: email,
      userId: _id,
      date: new Date(),
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    isWait(true);
    axios
      .post(`${process.env.API_URL}/fees`, formState, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormState({});
        setnumberOfPieces(500);
        setTimeout(() => {
          isWait(false);
          navigate("/feeslist");
          setnumberOfPieces(0);
        }, 4000);
      })
      .catch((err) => {
        isWait(false);
        console.log(err);
      });
  };

  const config = {
    angle: "192",
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: "10000",
    stagger: "9",
    width: "10px",
    height: "10px",
    perspective: "605px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    // <!-- Main Wrapper -->
    <div>
      <div className="confettie-wrap" ref={confetiRef}>
        <ReactConfetti numberOfPieces={numberOfPieces} config={config} />
      </div>

      {/* <!-- Page Wrapper --> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <!-- Page Header --> */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Add Fees</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/feeslist">Fees</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Fees</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- /Page Header --> */}

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h5 className="form-title">
                          <span>Fees Information</span>
                        </h5>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label>Student Id</label>
                          <input
                            disabled
                            type="text"
                            value={username || ""}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label>Full Name</label>
                          <input
                            disabled
                            type="text"
                            value={name || ""}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label>Fee Type</label>
                          <Select2
                            required
                            value={formState.fees_type || ""}
                            onChange={handleChange}
                            className="w-100"
                            name="fees_type"
                            data={options}
                            options={{
                              placeholder: "Select Type",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label>Teacher</label>
                          <Select2
                            required
                            value={formState.teacher || ""}
                            onChange={handleChange}
                            className="w-100"
                            name="teacher"
                            data={options1}
                            options={{
                              placeholder: "Select Teacher",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label>Pay to</label>
                          <Select2
                            required
                            value={formState.pay_to || ""}
                            onChange={handleChange}
                            className="w-100"
                            name="pay_to"
                            data={options1}
                            options={{
                              placeholder: "Select Teacher",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label>Fee Amount</label>
                          <input
                            onChange={handleChange}
                            value={formState.fees_amount || ""}
                            name="fees_amount"
                            type="number"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label>Fee Month</label>
                          <input
                            onChange={handleChange}
                            value={formState.fees_month || ""}
                            name="fees_month"
                            type="month"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          type="submit"
                          disabled={wait}
                          className="btn btn-primary"
                        >
                          {" "}
                          {wait ? "Please Wait..." : "Submit"}{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Page Wrapper --> */}
    </div>
    // <!-- /Main Wrapper -->
  );
};

export default Addfees;
