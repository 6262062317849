
import React, {useCallback, useEffect,useMemo,useState} from 'react';
import { Row, Col, Card, Media } from "react-bootstrap";
import { Link } from 'react-router-dom'
import Calendar from 'react-calendar';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentStatusData, getusers } from '../Redux/action/userAction';
import StudentStatusModal from './studentStatusModal';
import { useParams } from 'react-router-dom';






const Students = () => {
    let {id}  = useParams();
	
	const data = useSelector((state) => state.usersReducer.data);
	const auth = useSelector((state) => state.authReducer);

	const dispatch = useDispatch()
 
    useEffect(()=>{
        
        dispatch(getStudentStatusData(id, auth));
        
    },[id,auth])

	const [studentdata ,setstudentdata] = useState([])
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	
	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);
	
	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);


	
	const filteredItems = data.filter(
		item => item.username.toLowerCase().includes(filterText.toLowerCase()) || item.name.toLowerCase().includes(filterText.toLowerCase()),
	);

	const handlefilter = (e) => {		
		setFilterText(e.target.value);
	}
	
	const subHeaderComponentMemo = useMemo(() => {
		return (

			<div className="input-group w-50">
				<input id="search-input" type="search" className="form-control" placeholder="Search Name Or ID" onChange={(e)=>handlefilter(e)}  />  
				<button id="search-button" type="button" className="btn btn-primary">
				<i className="fa fa-search"></i>
				</button>
				
			</div> 

			
			
		);
	}, [filterText, resetPaginationToggle]);




	const contextActions = useMemo(() => {
		const handleDelete = () => {
			
			if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.full_name)}?`)) {
				setToggleCleared(!toggleCleared);
				
			}
		};

		return (
			<button key="delete" className='btn btn-danger' onClick={handleDelete} >
				Delete
			</button>
		);
	}, [data, selectedRows, toggleCleared]);
	




	const columns = [

		{ name: "Full Name", selector: (row) => row.name, sortable: true },
		{ name: "Student ID", selector: (row) => row.username, sortable: true },
		{ name: "Email", selector: (row) => row.email, sortable: true },
		{
			name:"Action",
			cell : (row) => <div className="actions">
			<button onClick={(e)=>setstudentdata(row)} className="btn btn-sm bg-success-light ms-2" data-bs-toggle="modal" data-bs-target="#studentstatusmodal" >
				<i  className="fas fa-pen" ></i>
			</button>
			
		</div>,
		}
		
		
	
	  ];
	
	 
	   

    return (
	<>
		{/* <!-- Main Wrapper --> */}
		<div >
			<StudentStatusModal studentdata={studentdata} />
			{/* <!-- Page Wrapper --> */}
			<div className="page-wrapper">
                <div className="content container-fluid">
				
					{/* <!-- Page Header --> */}
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Students</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
									<li className="breadcrumb-item active">Students</li>
								</ul>
							</div>
							<div className="col-auto text-end float-end me-auto">
								{/* <a href="#" className="btn btn-outline-primary ms-2"><i className="fas fa-download"></i> Download</a> */}
								<Link to="/addstudents" className="btn btn-primary"><i className="fas fa-plus"></i></Link>
							</div>
						</div>
					</div>
					{/* <!-- /Page Header --> */}
				
					<div className="row">
						<div className="col-sm-12">
							<div className="card card-table">
								<div className="card-body">
									<div className="table-responsive">
									<DataTable
                        title="Student List"
                        columns={columns}
                        pagination
                        highlightOnHover
						contextActions={contextActions}
						onSelectedRowsChange={handleRowSelected}
						clearSelectedRows={toggleCleared}
						data={filteredItems}
						paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
						subHeader
						subHeaderComponent={subHeaderComponentMemo}
						persistTableHead
						fixedHeader
						fixedHeaderScrollHeight="400px"
                      />
									</div>
								</div>
							</div>							
						</div>					
					</div>					
				</div>

				{/* <!-- Footer --> */}
				<footer>
					<p>Copyright © 2023 Ifastacademy.</p>					
				</footer>
				{/* <!-- /Footer --> */}

			</div>
			{/* <!-- /Page Wrapper --> */}

		</div>
		{/* <!-- /Main Wrapper --> */}

	</>
    );
}

export default Students;
