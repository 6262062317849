import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


import { useDispatch, useSelector } from "react-redux";
import { FindFeesList, getFees } from "../Redux/action/feesAction";
import DataTable from "react-data-table-component";

const Fees = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.feesReducer.data);
  const auth = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(FindFeesList(auth));
  },[auth]);

  const columns = [
    { name: "Full Name", selector: (row) => row.full_name, sortable: true },

    { name: "Student ID", selector: (row) => row.student_id, sortable: true },
    { name: "Fee Type", selector: (row) => row.fees_type, sortable: true },
    { name: "Pay To", selector: (row) => row.pay_to, sortable: true },
    { name: "Teacher", selector: (row) => row.pay_to, sortable: true },
    {
      name: "Date",
      selector: (row) => `${row.date}`.substring(0, 10),
      sortable: true,
    },

    { name: "Fee Amount", selector: (row) => row.fees_amount, sortable: true },
    

    { name: "Fee Month",  selector : (row) => `${(new Date(row.fees_month)).toString().substring(3,8)} ${(new Date(row.fees_month)).toString().substring(10, 15)}`},

    {
      name: "Status",
      selector: (row) => (
        <div className="text-end">
          {row.status ? (
            <span className="badge badge-success">paid</span>
          ) : (
            <span className="badge badge-warning">pending</span>
          )}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      {/* <!-- Main Wrapper --> */}
      <div >
       
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Fees List</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Fees List</li>
                  </ul>
                </div>
                <div className="col-auto text-end float-left mr-auto">
                  <a href="#" className="btn btn-outline-primary ms-2">
                    <i className="fas fa-download"></i> Download
                  </a>
                  <Link to="/add-fees-collection" className="btn btn-primary">
                    <i className="fas fa-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- /Page Header --> */}

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataTable
                        title="Fees List"
                        columns={columns}
                        data={data}
                        pagination
                        highlightOnHover
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Footer --> */}
          <footer>
            <p>Copyright © 2023 Ifastacademy.</p>
          </footer>
          {/* <!-- /Footer --> */}
        </div>
        {/* <!-- /Page Wrapper --> */}
      </div>
      {/* <!-- /Main Wrapper --> */}
    </>
  );
};

export default Fees;
