
import axios from "axios";
import { toast } from "react-toastify";

// import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';


export const signUp = (user,auth) => {
  const cookies = new Cookies();

  return (dispatch) => {
    axios
      .post(`${process.env.API_URL}/register`, user,{
        headers: {
          "Authorization": `Bearer ${auth.token}`,
          "Content-Type": "application/json"
        },
      })
      .then((e) => {

        toast.success(`Admin register successfully`, {
          position: "top-center", 
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        // cookies.set('ifastacademy-token', token.data);
        
        dispatch({
          type: "SIGN_UP",
          token: token.data,
        });
        
      })
      .catch((error) => {
        toast.error(error.response?.data, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
       
      });
  };
};

export const signIn = (logindata) => {
  const cookies = new Cookies();

  return  (dispatch) => {
    axios
      .post(`${process.env.API_URL}/login`, logindata)
      .then((token) => {

        cookies.set('ifastacademy-token', token.data);
        toast.success('welcome', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        dispatch({
          type: "SIGN_IN",
          token: token.data,
        });
      })
      .catch((error) => {
        toast.error(error.response?.data, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        
        // console.log(error.response);

     
      
      });
  };
};


export const ChangePassword = (data) => {
   
  return async(dispatch, getState, api) => {

    const id = toast.loading("Password updating..." ,{position: "top-center",})
   try{

       await axios.post(`${process.env.API_URL}/login/changepassword`, data)
        .then((e) => {
            toast.update(id, {render: e.response?.data, type: "success", isLoading: false,position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",});

            dispatch({
              type: "SIGN_OUT",
            });
            
          })
        .catch((error) => {
          toast.update(id, {render: error.response?.data, type: "error", isLoading: false,position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",});
          
          console.log(error.response?.data)
        });
    }
    catch(err){

      toast.update(id, {render: err.response?.data, type: "error", isLoading: false,position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",});
      
      
      console.log(err.response?.data)
             
    }
  }
  };


export const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_TODOS",
    });
    dispatch({
      type: "CLEAR_FEES",
    });
    
    dispatch({
      type: "SIGN_OUT",
    });

  };
};

export const loadUser = () => {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if(token){
      dispatch({
        type: "USER_LOADED",
        token,
      });
    } else return null;
  };
};

