export const  GET_ADMISSION="GET_ADMISSION"
export const SORTH="SORTH"
export const SORTL="SORTL"

import axios  from "axios"
import { toast } from "react-toastify"
import  { Navigate } from 'react-router-dom'

// import { setHeaders } from "../../api"

export const adddata=(data)=>{
    return {
        type:GET_ADMISSION,
        payload:data
    }
}


export const getAdmission=(auth)=>{
    return async(dispatch, getState, api) => {
         try {
            
             let res = await fetch(`${process.env.API_URL}/admission`,{
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${auth.token}`,
                    "Content-Type": "application/json"
                  }
               })
             
             let data=await res.json()
             return dispatch(adddata(data))
         } 
         catch (error) {
            console.log(error)
         }
      }
}

export const postAdmission = (data) => {
    return async(dispatch, getState, api) => {
     try{
        axios.post(`${process.env.API_URL}/admission`, data)
          .then((e) => {
           toast.success(e.data, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

          })
          .catch((error) => {
            
            toast.error(error.response?.data, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
                theme: "light",
                });
              console.log(error.response?.data)
              
          });
      }
      catch(err){
        console.log(err)
               
      }

    }
    };


    export const postAdmissionBYReferral = (data,refeeralid) => {
        return async(dispatch, getState, api) => {
         try{
            axios.post(`${process.env.API_URL}/admission/${refeeralid}`, data)
              .then((e) => {
               toast.success(e.data, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
    
              })
              .catch((error) => {
                
                toast.error(error.response?.data, {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: false,
                    theme: "light",
                    });
                  console.log(error.response?.data)
                  
              });
          }
          catch(err){
            console.log(err)
                   
          }
    
        }
        };

    export const PatchAdmission=(data,id,auth)=>{
        return async(dispatch, getState, api) => {
            try {
                await fetch(`${process.env.API_URL}/admission/${id}`,{
                        method:"PATCH",
                        headers: {
                            "Authorization": `Bearer ${auth.token}`,
                            "Content-Type": "application/json"
                        },
                        body:JSON.stringify(data)
                    })
                    dispatch(getAdmission())
                    toast.success('Enquiry Updated', {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                
            } 
            catch (error) {
                toast.warn(error, {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                
                console.log(error)
            }
    }
    }


    export const DeleteEnquiry = (id,auth)=>{
        return async(dispatch, getState, api)=>{
            
            try {
                const toastid = toast.loading("Enquiry Deleting..." ,{position: "top-center",})
                await fetch(`${process.env.API_URL}/admission/${id}`,{
                        method:"DELETE",
                        headers: {
                            "Authorization": `Bearer ${auth.token}`,
                            "Content-Type": "application/json"
                          }
                })
                toast.update(toastid, {render: "Enquiry deleted successfully", type: "success", isLoading: false,position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                      
                dispatch(getAdmission(auth))
            } 
            catch (error) {
                console.log(error)
            }
    }
    }
    