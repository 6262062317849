import { applyMiddleware, combineReducers, compose, legacy_createStore } from 'redux';
import authReducer from './reducer/userReducer';
// import todoReducer from './todoReducer';
import thunk from "redux-thunk"
import courseReducer from './reducer/courseReducer';
import admissionReducer from "./reducer/admissionReducer"
import certificateimgRerducer from './reducer/certificateReducer';
import instructorReducer from './reducer/instructorReducer';
import studentReducer from './reducer/studentReducer';
import usersReducer from './reducer/usersReducer';
import feesReducer from './reducer/feesReducer';
import referralsReducer from './reducer/referralsReducer';



const rootReducer = combineReducers({ 
    authReducer: authReducer,
    courseReducer:courseReducer,
    admissionReducer: admissionReducer, 
    certificateimgRerducer: certificateimgRerducer,
    instructorReducer: instructorReducer,
    studentReducer: studentReducer,
    usersReducer: usersReducer,
    feesReducer: feesReducer,
    referralsReducer:referralsReducer
})

export  const store = legacy_createStore(rootReducer, compose(applyMiddleware(thunk)))

