import React from 'react'
import { useState } from 'react'
import Avatar from 'react-avatar-edit'
import { useDispatch, useSelector } from 'react-redux'
import { PatchAdmission, postStudents } from '../Redux/action/studentAction'
import Select2 from 'react-select2-wrapper';
import axios from 'axios';
import { toast } from 'react-toastify'
import { PatchFees } from '../Redux/action/feesAction'
import { useEffect } from 'react'


export default function FeesModal({status,setstatus}) {
  const auth = useSelector((state) => state.authReducer);

  const dispatch = useDispatch()
  
         

  return (
    
    <div className="modal fade" id="feesmodal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Update Fee</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
        <span>Full Name : {status.full_name}</span><br/>
          <span>Student ID : {status.student_id}</span><br/>
          <span>Pay To : {status.pay_to}</span><br/>
          <span>Fee Type : {status.fees_type}</span><br/>
          <span>Fee Amount : {status.fees_amount}</span><br/>
          <span>Fee Month : {(new Date(status.fees_month)).toString().substring(3,8)} {(new Date(status.fees_month)).toString().substring(10, 15)}</span>
      
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button 
          onClick={()=>{
            Object.assign(status, {accept_by:auth.name});
            dispatch(PatchFees(status,status._id,auth))
          }}
           data-bs-dismiss="modal" aria-label="Close" type="button" className="btn btn-primary">{status.status ? "Reject": "Accept"}</button>
        </div>
			</div>
      </div>
    </div>
  </div>
    
  )
}
