import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AttendanceQuery = () => {
  const [data, setData] = useState([]);
  const auth = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [attendancedate, setattedandancedate] = useState("");
  const [buttonloading, setbuttonloading] = useState(false);

  const getData = () => {
    setLoading(true);
    const headers = { Authorization: `Bearer ${auth.token}` };

    axios
      .get(`${process.env.API_URL}/attendance/attendanceauto`, { headers })
      .then((response) => {
        setData(response.data && response.data[0]?.UserAttendance);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  // katill boyy

  useEffect(() => {
    getData();
  }, []);

  const convertTimeFormat = (inputTime) => {
    const timeObj = new Date(`2000-01-01T${inputTime}`);
    const formattedTime = timeObj.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  function calculateTotalWorkingHours(timesArray) {
    if (timesArray.length < 2) {
      // If there are not enough times in the array, return "0:00:00"
      return "-";
    }

    const start = new Date(`1970-01-01T${timesArray[0]}`);
    const end = new Date(`1970-01-01T${timesArray[timesArray.length - 1]}`);
    const millisecondsInAnHour = 60 * 60 * 1000;

    // Calculate the difference in milliseconds between end and start times
    const timeDifference = end - start;

    // Calculate the total working hours in hours, minutes, and seconds
    const totalWorkingHours = Math.floor(timeDifference / millisecondsInAnHour);
    const remainingMilliseconds = timeDifference % millisecondsInAnHour;
    const totalWorkingMinutes = Math.floor(remainingMilliseconds / 60000);
    const totalWorkingSeconds = Math.floor(
      (remainingMilliseconds % 60000) / 1000
    );

    // Format the total working hours, minutes, and seconds as HH:MM:SS
    const formattedHours = totalWorkingHours.toString().padStart(2, "0");
    const formattedMinutes = totalWorkingMinutes.toString().padStart(2, "0");
    const formattedSeconds = totalWorkingSeconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const columns = [
    { name: "Full Name", selector: (row) => row.student_name, sortable: true },
    { name: "Student ID", selector: (row) => row.student_id, sortable: true },
    { name: "Batch Time", selector: (row) => row.batch_time, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "ID Card No", selector: (row) => row.id_card_no, sortable: true },

    {
      name: "First In",
      selector: (row) => (
        <p style={{ display: "flex", flexDirection: "column" }}>
          {" "}
          {row.inorout?.map((e, index) => (
            <span key={index} style={{ color: "green" }}>
              {index % 2 == 0 ? convertTimeFormat(e) : null}
            </span>
          ))}{" "}
        </p>
      ),
      sortable: true,
    },

    {
      name: "Last Out",
      selector: (row) => (
        <p style={{ display: "flex", flexDirection: "column" }}>
          {" "}
          {row.inorout?.map((e, index) => (
            <span key={index} style={{ color: "red" }}>
              {index % 2 != 0 ? convertTimeFormat(e) : null}
            </span>
          ))}{" "}
        </p>
      ),
      sortable: true,
    },

    {
      name: "Total Time",
      selector: (row) => calculateTotalWorkingHours(row.inorout),
      sortable: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        backgroundColor: "#f8f8f8",
        fontWeight: 900,
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(245, 243, 243)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "5px",
        outlineWidth: "0",
      },
    },
    cells: {
      style: {
        border: "1px solid #ccc", // Add border to cells
      },
    },
  };

  const customStyles2 = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: 200,
      minWidth: 200,
    }),
  };

  const handleFindAttendance = () => {
    setbuttonloading(true);
    const headers = { Authorization: `Bearer ${auth.token}` };

    axios
      .post(
        `${process.env.API_URL}/attendance/attendancefindbydate`,
        { date: attendancedate },
        {
          headers,
        }
      )
      .then((response) => {
        setData(response.data && response.data[0]?.UserAttendance);

        setTimeout(() => {
          setbuttonloading(false);
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    console.log("search attenddace");
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            width={"200px"}
          />
          <input
            type="date"
            defaultValue={new Date().toISOString().substr(0, 10)}
            onChange={(e) => setattedandancedate(e.target.value)}
            placeholder="small size"
            width={"150px"}
          />
          <button
            onClick={() => handleFindAttendance()}
            disabled={buttonloading}
            className={`btn btn-primary${
              buttonloading ? " loading" : ""
            } btn-sm`}
            type="submit"
          >
            {buttonloading ? (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Submit"
            )}
            {buttonloading && <span className="loading-text">Loading...</span>}
          </button>

          {/* <button
            onClick={() => handleFindAttendance()}
            class="btn btn-primary btn-sm"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            <span role="status">Loading...</span>
          </button> */}
        </div>
      </>
    );
  }, [searchText, customStyles2]);

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <!-- Page Header --> */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Students Attendance</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Students Attendance
                  </li>
                </ul>
              </div>
              <div className="col-auto text-end float-end me-auto">
                {/* <a href="#" className="btn btn-outline-primary ms-2"><i className="fas fa-download"></i> Download</a> */}
                <Link to="/addstudents" className="btn btn-primary">
                  <i className="fas fa-plus"></i>
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- /Data table --> */}
          {/* <DataTable /> */}

          {loading ? (
            <center>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          ) : (
            <div>
              <style>
                {`
                          ::-webkit-scrollbar {
                            width: 5px; /* Set the width of the scrollbar */
                            height: 5px;
                          
                          }
                        
                          ::-webkit-scrollbar-track {
                            background-color: #f1f1f1; /* Set the background color of the scrollbar track */
                          }
                        
                          ::-webkit-scrollbar-thumb {
                            background-color: #C1C1C1; /* Set the color of the scrollbar thumb */
                          } 
                        
                          ::-webkit-scrollbar-thumb:hover {
                            background-color: #555; /* Set the color of the scrollbar thumb on hover */
                          }
                        `}
              </style>

              <DataTable
                title="Student Attendance Table"
                columns={columns}
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                highlightOnHover
                subHeader
                persistTableHead
                customStyles={customStyles}
                fixedHeaderScrollHeight="400px"
                data={filteredData}
                fixedHeader
                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleCleared}
                // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // contextActions={contextActions}
                // subHeaderComponent={subHeaderComponentMemo}
              />
            </div>
          )}
        </div>

        {/* <!-- Footer --> */}
        <footer>
          <p>Copyright © 2023 Ifastacademy.</p>
        </footer>
        {/* <!-- /Footer --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </div>
  );
};
export default AttendanceQuery;
