import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { postStudentStatus } from "../Redux/action/studentAction";

export default function StudentStatusModal({ studentdata, setstudentdata }) {


	const auth = useSelector((state) => state.authReducer)
  const [Certifiedstatus, setCertifiedstatus] = useState(false)

  const dispatch = useDispatch()


const handleChange = (e)=>{
  const {name, value} = e.target

  setstudentdata({
      ...studentdata,
      [name] : value,
  })

}



const handleSubmit = (e)=>{
        e.preventDefault()
        dispatch(postStudentStatus(studentdata, auth))

}


  return (
    <div
      className="modal fade"
      id="studentstatusmodal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Update Student
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <span>Full Name : {studentdata.name}</span>
            <br />
            <span>Student ID : {studentdata.username}</span>
            <br />
            <span>Email : {studentdata.email}</span>
            <br />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <h5 className="form-title">
                    <span>Student Information</span>
                  </h5>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label>Batch Time</label>
                    <select name="batch_time" value={studentdata.batch_time || ''} onChange={handleChange}  className="form-control" required>
                        <option value="">Select a time</option>
                        <option value="10:00am">10:00 am</option>
                        <option value="11:00am">11:00 am</option>
                        <option value="12:00pm">12:00 pm</option>
                        <option value="1:00pm">1:00 pm</option>
                        <option value="2:00pm">2:00 pm</option>
                        <option value="3:00pm">3:00 pm</option>
                        <option value="4:00pm">4:00 pm</option>
                        <option value="5:00pm">5:00 pm</option>
                        <option value="6:00pm">6:00 pm</option>
                        <option value="7:00pm">7:00 pm</option>
                        <option value="8:00pm">8:00 pm</option>
                        <option value="9:00pm">9:00 pm</option>
                        <option value="10:00pm">10:00 pm</option>
                     </select>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label>Section</label>
                    <select className="form-control" onChange={handleChange} value={studentdata.section || ''} name="section" id="" required>
                        <option  value="">Select Section</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                    </select>
                  </div>
                </div>
             
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label>ID Card No</label>
                    <input name="id_card_no" type="text" onChange={handleChange} value={studentdata.id_card_no || ''} className="form-control"  placeholder="ID Card No" />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label>Teacher</label>
                    <div>
                    <select className="form-control" name="teacher" onChange={handleChange} value={studentdata.teacher || ''} required>
                        <option  value="">Select Teacher</option>
                        <option value="Shri Ram">Shri Ram</option>
                        <option value="Vishal Kohli">Vishal Kohli</option>
                        <option value="Neeraj">Neeraj</option>
                    </select>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label>Status</label>
                    <div>
                    <select className="form-control" onChange={handleChange} value={studentdata.status || ''} name="status" id="" required>
                        <option  value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Deactive">Deactive</option>
                        <option value="Dropout">Dropout</option>
                        <option value="Completed">Completed</option>
                        <option value="Certified">Certified</option>
                    </select>
                    </div>
                  </div>
                </div>
                
                {studentdata.status =='Certified' ? <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <div>
                    <label>Certificate Url</label>
                    <input  name="certificate" onChange={handleChange} value={studentdata.certificate || ''} className="form-control"  placeholder="Certificate Url" type="text" />
                    </div>
                  </div>
                </div>:null}


                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label>Remarks</label>
                    <textarea className="form-control" value={studentdata.remarks || ''} onChange={handleChange} name="remarks" id="" cols="30" rows="10"></textarea>
                
                  </div>
                </div>
              </div>

              <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                aria-label="Close"
                type="submit"
                className="btn btn-danger"
                data-bs-dismiss="modal"

              >
                Save
              </button>
            </div>
            </form>
          
          </div>
        </div>
      </div>
    </div>
  );
}
