import React from 'react';
import ReactDOM from 'react-dom';


window.$ = window.jQuery = require("jquery");

import './assets/plugins/simple-calendar/simple-calendar.css';
import './assets/plugins/simple-calendar/jquery.simple-calendar.js';
//Datatable Modules
import DataTable from 'datatables.net';
import Datatable5 from 'datatables.net-bs5'

import './assets/plugins/datatables/datatables.min.css';

// Bootstrap CSS
import './assets/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/plugins/fontawesome/css/all.css';
import './assets/plugins/fontawesome/css/all.min.css';
import './assets/plugins/fontawesome/css/fontawesome.min.css';
import './assets/plugins/apexchart/apexcharts.min.js';

import 'react-select2-wrapper/css/select2.css';
import './assets/plugins/datatables/jquery.dataTables.min.js';

import "react-datepicker/dist/react-datepicker.css";
import 'react-calendar/dist/Calendar.css';



// Custom CSS
import './assets/css/style.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import { BrowserRouter } from 'react-router-dom';
import config from 'config';
ReactDOM.render(
  <Provider store={store} >
     <BrowserRouter basename={`${config.publicPath}`}>
   <App />
   </BrowserRouter>
   </Provider>,
  document.getElementById('root')
);
