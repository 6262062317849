import React from 'react'
import { useDispatch } from 'react-redux';
import { PatchCourse } from '../Redux/action/courseAction';

export default function CourseModal({Course, SetCourse}) {


    const dispatch = useDispatch()

    const  handlesubmit = (e) =>{
		e.preventDefault();
		dispatch(PatchCourse(Course,Course._id))


	}

	const handleChange = (e) =>{
		const {name , value}= e.target;

		SetCourse({
			...Course,
			[name]: value,
		});
	}
  return (
    
    <div className="modal fade" id="coursemodal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Update Course</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">

        <form onSubmit={handlesubmit}>
										<div className="row">
											<div className="col-12">
												<h5 className="form-title"><span>Course Information</span></h5>
											</div>
											<div className="col-12 col-sm-6">  
												<div className="form-group">
													<label>Course Name</label>
													<input onChange={handleChange} value={Course.course || ''} type="text" name="course" className="form-control" required/>
												</div>
											</div>
											
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Fee</label>
													<input onChange={handleChange} type="number" value={Course.coursefees || ''} name="coursefees" className="form-control" required/>
												</div>
											</div>
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Duration</label>
													<input onChange={handleChange} type="text" value={Course.duration || ''} name="duration" className="form-control" required/>
												</div>
											</div>
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Programs</label>
													<input onChange={handleChange} name="programs" value={Course.programs || ''} type="text" className="form-control" required/>
												</div>
											</div>
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Description</label>
													<textarea name="description" onChange={handleChange} value={Course.description || ''} type="text" className="form-control" required />
													
												</div>
											</div>
											
											<div className="col-12">
												<button type="submit" data-bs-dismiss="modal" className="btn btn-primary">Submit</button>
											</div>
										</div>
									</form>
       
        </div>
     
      </div>
    </div>
  </div>
  )
}
