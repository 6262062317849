export const  GET_FEES="GET_FEES"
export const  FIND_FEES_LIST = "FIND_FEES_LIST"
import JSConfetti from 'js-confetti'


import axios  from "axios"
import { toast } from "react-toastify"

// import { setHeaders } from "../../api"

export const feesdata=(data)=>{
    return {
        type:GET_FEES,
        payload:data
    }
}

export const findfeeslist=(data)=>{
    return {
        type:FIND_FEES_LIST,
        payload:data
    }
  }



  
export const getFees=(auth)=>{
  
    return async(dispatch, getState, api) => {
         try {
             let res=await fetch(`${process.env.API_URL}/fees`,{
              method: 'GET',
              headers: {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
              }
             })
             let data=await res.json()
             dispatch(feesdata(data))
         } 
         catch(error){
          toast.error('Invalid auth token...', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.log(error,"token")
         }
      }
}


export const getFeesPending = (auth) =>{
  return async(dispatch, getState, api) => {
       try {
           let res=await fetch(`${process.env.API_URL}/fees/statusfalse`,{
            method: 'GET',
            headers: {
              "Authorization": `Bearer ${auth.token}`,
              "Content-Type": "application/json"
            }
           })
           let data=await res.json()
           dispatch(feesdata(data))
       } 
       catch(error){
        toast.error('Invalid auth token...', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          console.log(error,"token")
       }
    }
}


export const FindFeesList= (auth)=>{
    return async(dispatch)=>{
      try{
        fetch(`${process.env.API_URL}/fees/feeslist`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${auth.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(auth)
        })
          .then(response => {
            if (!response.ok) {
              console.log("response not ok")
            }
            return response.json();
          })
          .then(data => {
            dispatch(findfeeslist(data))

          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
    }
      catch(err){
        toast.error(err.response?.data, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      
  }}


export const postFees = (data, auth) => {
   
    return async(dispatch, getState, api) => {

      const id = toast.loading("Fee updating..." ,{position: "top-center",})
     try{
      
         await axios.post(`${process.env.API_URL}/fees`, data,{
          headers: {
            "Authorization": `Bearer ${auth.token}`,
            "Content-Type": "application/json"
          }
         })
          .then(() => {
            const jsConfetti = new JSConfetti()
              toast.update(id, {render: "Fee added successfully", type: "success", isLoading: false,position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",});
              
                setnumberOfPieces(500)
                setTimeout(() => {
                  window.location.href = 'https://www.ifastacademy.com/';
                  setIsLoading(false);

              },3000);
                dispatch(findfeeslist(auth))
              })
              
        
      }
      catch(err){
        toast.update(id, {render: err.response?.data, type: "error", isLoading: false,position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",});

        console.log(err.response?.data,)
               
      }
    }
    };


    export const DeleteFee = (id, auth)=>{
      return async(dispatch, getState, api)=>{
        const toastid = toast.loading("Fee Deleting..." ,{position: "top-center",})
          try {
              await fetch(`${process.env.API_URL}/fees/${id}`,{
                      method:"DELETE",
                      headers: {
                        "Authorization": `Bearer ${auth.token}`,
                        "Content-Type": "application/json"
                      }
              })
              toast.update(toastid, {render: "Fee deleted successfully", type: "success", isLoading: false,position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                    
              dispatch(getFeesPending(auth))
          } 
          catch (error) {
            toast.update(toastid, {render: error, type: "error", isLoading: false,position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
              console.log(error)
          }
  }
  }
  

  export const DeleteSelectedFees = (data,auth) => {
    return async(dispatch, getState, api) => {
      
      const id = toast.loading("Fees Deleting..." ,{position: "top-center",})
      try{
   
 
          await axios.post(`${process.env.API_URL}/fees/deleteselected`, data,{
            headers: {
              "Authorization": `Bearer ${auth.token}`,
              "Content-Type": "application/json"
            }
          })
           .then(() => {
               toast.update(id, {render: "Fee Deleted Successfully", type: "success", isLoading: false,position: "top-center",
               autoClose: 2000,
               hideProgressBar: true,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",});
               
             })
             dispatch(getFeesPending(auth))
             
           .catch((error) => {
             alert(error)
             console.log(error)
 
           });
       }
       catch(err){
         toast.update(id, {render: err.response?.data, type: "error", isLoading: false,position: "top-center",
               autoClose: 2000,
               hideProgressBar: true,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",});
               
         console.log(err)
                
       }
     }
     };
    
    export const PatchFees=(data,feesid,auth)=>{

        return async(dispatch, getState, api) => {
            try {
                const id = toast.loading("Fee updating..." ,{position: "top-center",})

                await fetch(`${process.env.API_URL}/fees/${feesid}`,{
                        method:"PATCH",
                        headers: {
                          "Authorization": `Bearer ${auth.token}`,
                          "Content-Type": "application/json"
                        },
                        body:JSON.stringify(data)
                    })
                    dispatch(getFees(auth))
                    toast.update(id, {render: "Fee updated successfully", type: "success", isLoading: false,position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",});
                        
              
            } 
            catch (error) {
                console.log(error.response?.data)
            }
    }
    }



    export const PatchFeesPending=(data,feesid,auth)=>{

      return async(dispatch, getState, api) => {
        const id = toast.loading("Fee updating..." ,{position: "top-center",})
          try {

              await fetch(`${process.env.API_URL}/fees/${feesid}`,{
                      method:"PATCH",
                      headers: {
                        "Authorization": `Bearer ${auth.token}`,
                        "Content-Type": "application/json"
                      },
                      body:JSON.stringify(data),
                      
                     
                  })
                  dispatch(getFeesPending(auth))
                  toast.update(id, {render: "Fee updated successfully", type: "success", isLoading: false,position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",});
                      
              
          } 
          catch (error) {

            toast.update(id, {render: error, type: "error", isLoading: false,position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",});
            
              console.log(error.response?.data)
          }
  }
  }

