

export const GET_STUDENTS="GET_STUDENTS"
export const FIND_USER = "FIND_USER"


export const studentReducer = (store={data:[]},{type,payload})=>{
    
    switch(type){
     case GET_STUDENTS:
         return {...store,data:payload}
     case FIND_USER:
        return {...store,data:payload}
        default:
            return store
    }
}

export default studentReducer;
 