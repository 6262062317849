import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo-white.png";
import Loginbg from "../assets/img/login.jpg";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Forgotpassword = (props) => {
  const dispatch = useDispatch();
  const [Otp, SetOtp] = useState();
  const [status, setstauts] = useState(true);
  const [status2, setstauts2] = useState(true);

  const auth = useSelector((state) => state.authReducer);
  const navigate = useNavigate();

  const [FormState, setFormState] = useState({});



  function handleChange(e) {
    const { name, value } = e.target;
    setFormState({
      ...FormState,
      [name]: value,
    });
  }

  useEffect(() => {
    if (auth.token) {
      navigate("/dashboard");
    }
    if (auth._id != null) {
      navigate("/dashboard");
    } else if (auth._id == null) {
      navigate("/forgotpassword");
    }
  }, [auth]);

  const handlesubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(`${process.env.API_URL}/users/forgotpassword`, FormState)
      .then((e) => {
        toast.success(e.data, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setstauts(false);
      })
      .catch((error) => {
        toast.error(error.response?.data, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(error.response?.data);
      });
  };


  
  const handleOtpsubmit = async(e) =>{
		e.preventDefault();

	await axios
      .post(`${process.env.API_URL}/users/forgototp`, FormState)
      .then((e) => {
        toast.success(e.data, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setstauts2(false);
      })
      .catch((error) => {
        toast.error(error.response?.data, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(error.response?.data);
      });
	

}


const handlePasswordSubmit = async(e) =>{
	e.preventDefault();

	if (FormState.newpassword != FormState.confirmpassword) {
		// console.log(formState)
		toast.warn(`both passwords are not matching`, {
		  position: "top-center",
		  autoClose: 2000,
		  hideProgressBar: true,
		  closeOnClick: true,
		  pauseOnHover: true,
		  draggable: true,
		  progress: undefined,
		  theme: "light",
		});
	  }
	else{

await axios
  .post(`${process.env.API_URL}/users/forgotpasswordcomfirm`, FormState)
  .then((e) => {
	toast.success(e.data, {
	  position: "top-center",
	  autoClose: 2000,
	  hideProgressBar: true,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	});

	navigate('/login')
	


	
  })
  .catch((error) => {
	toast.error(error.response?.data, {
	  position: "top-center",
	  autoClose: 2000,
	  hideProgressBar: true,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	});
	console.log(error.response?.data);
  });

}

}





  return (
    <>
      {/* <!-- Main Wrapper --> */}
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div
                className="login-left"
                style={{ backgroundImage: "url(" + Loginbg + ")" }}
              >
                <img className="img-fluid" src={Logo} alt="Logo" />
              </div>
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Forgot Password?</h1>
                  <p className="account-subtitle">
                    Enter Your Email
                  </p>

                  {/* <!-- Form --> */}
				  {status2 ? (status ? (
                    <form onSubmit={handlesubmit}>
                      <div className="form-group">
                        <input
                          onChange={handleChange}
                          className="form-control"
                          name="userlogin"
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div className="form-group mb-0">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  ) : (
					<form onSubmit={handleOtpsubmit}>
                    <div>
                      <div className="col-lg-12">
                        <label>Enter Otp</label>
                        <div className="input-group">
                          <input
                            type="text"
                            onChange={handleChange}
                            name="otp"
                            className="form-control"
                            placeholder="Enter Otp"
                            required
                          />
                        </div>
                        <div>
                          <br></br>
                          <p>
                            Please check the SPAM folder for OTP if mail not
                            received.
                          </p>
                        </div>
                      </div>

                      <button
                        className="btn btn-primary mt-5"
                        type="submit"
                      >
                        Verify OTP
                      </button>
                    </div>
					</form>
                  )):(
                          <form onSubmit={handlePasswordSubmit}>
                            <div className="foz rm-group">
                              <label>New Password</label>
                              <input
                                onChange={handleChange}
                                type="text"
                                name="newpassword"
                                placeholder="New Password"

                                required
                                className="form-control"
                                
                              />
                            </div>
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <input
                                onChange={handleChange}
                                type="text"
                                placeholder="Confirm Password"

                                name="confirmpassword"
                                required
                                className="form-control"
                              />
                            </div>
                            <button className="btn btn-primary" type="submit">
                            Update Now
                            </button>
                          </form>
                      )}
                  
                  {/* <!-- /Form --> */}

                  <div className="text-center dont-have">
                    Remember your password? <Link to="/login">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Main Wrapper -->     */}
    </>
  );
};

export default Forgotpassword;
