import React , {components, useState} from 'react';
import { Link } from 'react-router-dom'
import LogoImg from './assets/img/logo.png';
import LogoSmallImg from './assets/img/logo-small.png';
import img1 from './assets/img/profiles/avatar-01.jpg';
import img2 from './assets/img/profiles/avatar-02.jpg';
import img11 from './assets/img/profiles/avatar-11.jpg';
import img17 from './assets/img/profiles/avatar-17.jpg';
import img13 from './assets/img/profiles/avatar-13.jpg';
import profile_avatar from './assets/img/profiles/profile_avatar.jpg'
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from './Redux/action/authAction';



const Header = () => {
    // const handlesidebar=()=>{
	// 	document.body.classList.toggle('mini-sidebar');
    // }
    // const handletoggle = () =>{
    //     var $wrapper = $('.main-wrapper');
	// 	// document.body.classList.toggle('main-wrapper');
    //     $wrapper.toggleClass('slide-nav');
           
    //     $(".sidebar-overlay").on("click", function () {
	// 		$wrapper.removeClass('slide-nav');
	// 		$(".sidebar-overlay").removeClass("opened");
	// 		$('html').removeClass('menu-opened');
	// 	});

    // }

    
    let handlesidebar = () => {
        document.body.classList.toggle('mini-sidebar');
    }

	const  handletoggle = ()=> {
		// Mobile menu sidebar overlay
	
		var $wrapper = $('.main-wrapper');
		
		$('body').append('<div class="sidebar-overlay"></div>');
		$(document).on('click', '#mobile_btn', function() {
			$wrapper.toggleClass('slide-nav');
			$('.sidebar-overlay').toggleClass('opened');
			$('html').addClass('menu-opened');
			
            $(".sidebar-overlay").on("click", function () {
                $wrapper.removeClass('slide-nav');
                $(".sidebar-overlay").removeClass("opened");
                $('html').removeClass('menu-opened');
            });

            $(".close_btn").on("click", function () {
                $wrapper.removeClass('slide-nav');
                $(".sidebar-overlay").removeClass("opened");
                $('html').removeClass('menu-opened');
            });
		});
		
		// Sidebar overlay
		
		
	}
    
	
    



const dispatch = useDispatch();

    const auth = useSelector((state) => state.authReducer)
   const finduser = useSelector((state)=>state.studentReducer.data)
    




    return (
        <>
            {/* <!-- Header --> */}
           <div className="header">

                {/* <!-- Logo -->*/}
                <div className="header-left">
                    <Link to="/" className="logo">
                        <img src={LogoImg} alt="Logo" />
                    </Link>
                    <Link to="/" className="logo logo-small">
                        <img src={LogoSmallImg} alt="Logo"/>
                    </Link>
                </div>
                {/* <!-- /Logo --> */}
                
                <a  id="toggle_btn" onClick={handlesidebar}>
                    <i className="fas fa-align-right"></i>
                </a>
                
                {/* <!-- Search Bar --> */}
                <div className="top-nav-search">
                    <form>
                        <input type="text" className="form-control" placeholder="Search here"/>
                        <button className="btn" type="submit"><i className="fas fa-search"></i></button>
                    </form>
                </div>
                {/* <!-- /Search Bar --> */}
                
                {/* <!-- Mobile Menu Toggle --> */}
                <a className="mobile_btn" id="mobile_btn" onClick={()=>handletoggle()}>  
                    <i className="fas fa-bars"></i>
                </a>
                {/* <!-- /Mobile Menu Toggle --> */}
                
                {/* <!-- Header Right Menu --> */}
                <ul className="nav user-menu">

                    {/* <!-- Notifications --> */}
                    <li className="nav-item dropdown noti-dropdown">
                        {/* <a  className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                            <i className="far fa-bell"></i> <span className="badge badge-pill">3</span>
                        </a> */}
                        <div className="dropdown-menu notifications">
                            <div className="topnav-dropdown-header">
                                <span className="notification-title">Notifications</span>
                                <a  className="clear-noti"> Clear All </a>
                            </div>
                            <div className="noti-content">
                                <ul className="notification-list">
                                    <li className="notification-message">
                                        <a >
                                            <div className="media d-flex">
                                                <span className="avatar avatar-sm flex-shrink-0">
                                                    <img className="avatar-img rounded-circle" alt="User Image" sec={finduser.userimg!=undefined ? (finduser.userimg):(profile_avatar)}/>
                                                </span>
                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details"><span className="noti-title">Carlson Tech</span> has approved <span className="noti-title">your estimate</span></p>
                                                    <p className="noti-time"><span className="notification-time">4 mins ago</span></p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="notification-message">
                                        <a>
                                            <div className="media d-flex">
                                                <span className="avatar avatar-sm flex-shrink-0">
                                                    <img className="avatar-img rounded-circle" alt="User Image" src={finduser.userimg!=undefined ? (finduser.userimg):(profile_avatar)}/>
                                                </span>
                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details"><span className="noti-title">International Software Inc</span> has sent you a invoice in the amount of <span className="noti-title">$218</span></p>
                                                    <p className="noti-time"><span className="notification-time">6 mins ago</span></p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="notification-message">
                                        <a >
                                            <div className="media d-flex">
                                                <span className="avatar avatar-sm flex-shrink-0">
                                                    <img className="avatar-img rounded-circle" alt="User Image" src={finduser.userimg!=undefined ? (finduser.userimg):(profile_avatar)}/>
                                                </span>
                                                <div className="media-body flex-grow-1">
                                                <p className="noti-details"><span className="noti-title">John Hendry</span> sent a cancellation request <span className="noti-title">Apple iPhone XR</span></p>
                                                <p className="noti-time"><span className="notification-time">8 mins ago</span></p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="notification-message">
                                        <a >
                                            <div className="media d-flex">
                                                <span className="avatar avatar-sm flex-shrink-0">
                                                    <img className="avatar-img rounded-circle" alt="User Image" src= {finduser.userimg!=undefined ? (finduser.userimg):(profile_avatar)}/>
                                                </span>
                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details"><span className="noti-title">Mercury Software Inc</span> added a new product <span className="noti-title">Apple MacBook Pro</span></p>
                                                    <p className="noti-time"><span className="notification-time">12 mins ago</span></p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="topnav-dropdown-footer">
                                <a >View all Notifications</a>
                            </div>
                        </div>
                    </li>
                    {/* <!-- /Notifications --> */}
                    
                    {/* <!-- User Menu --> */}
                    <li className="nav-item dropdown has-arrow">
                        <a  className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                            <span className="user-img ms-2"><img className="rounded-circle" src= {finduser.userimg!=undefined ? (finduser.userimg):(profile_avatar)} width="31"/></span>
                        </a>
                        <div className="dropdown-menu">
                            <div className="user-header">
                                <div className="avatar avatar-sm">
                                    <img src={finduser.userimg!=undefined ? (finduser.userimg):(profile_avatar)} alt="User Image" className="avatar-img rounded-circle"/>
                                </div>
                                <div className="user-text">
                                    <h6>{auth.name}</h6>
                                    <p className="text-muted mb-0">{auth.role}</p>
                                </div>
                            </div>
                            <Link className="dropdown-item" to="/profile">My Profile</Link>
                            <Link className="dropdown-item" to="/inbox">Inbox</Link>
                            <Link className="dropdown-item" to="/login" onClick={()=>{dispatch(signOut())}} >Logout</Link>
                        </div>
                    </li>
                    {/* <!-- /User Menu --> */}

                </ul>
                 {/* <!-- /Header Right Menu --> */}
                
            </div>
            {/* <!-- /Header -->                         */}
           
        </>
        
    );
}

export default Header;
