
export const GET_INSTRUCTORS="GET_INSTRUCTORS"



export const instructorReducer = (store={data:[]},{type,payload})=>{
    switch(type){
     case GET_INSTRUCTORS:
         return {...store,data:payload}
        default:
             return store
    }
}

export default instructorReducer;
 