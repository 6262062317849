
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Link } from 'react-router-dom'
import Header from "../header";
import Sidebar from '../sidebar';

import { useDispatch, useSelector } from 'react-redux';
import { getFees } from '../Redux/action/feesAction';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import ReferralModal from './refeeralModal/ReferralModal';
import { getReferralsforAdmin } from '../Redux/action/referralsAction';



const Refeeralstatus = () => {

	const dispatch = useDispatch()
	const auth = useSelector((state) => state.authReducer);
    const myreferralsdata = useSelector((state) => state.referralsReducer.data);

	const [status ,setstatus] = useState([])
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [data, setdata] = useState([])
	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);
	
	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

useEffect(()=>{

    dispatch(getReferralsforAdmin(auth))
},[])
	
	// const filteredItems = data.filter(
	// 	item => item.student_id.toLowerCase().includes(filterText.toLowerCase()) || item.full_name.toLowerCase().includes(filterText.toLowerCase()),
	// );

	const handlefilter = (e) => {		
		setFilterText(e.target.value);
	}
	
	// const subHeaderComponentMemo = useMemo(() => {
	// 	return (

	// 		<div className="input-group w-50">
	// 			<input id="search-input" type="search" className="form-control" placeholder="Search Name Or ID" onChange={(e)=>handlefilter(e)}  />  
	// 			<button id="search-button" type="button" className="btn btn-primary">
	// 			<i className="fa fa-search"></i>
	// 			</button>
				
	// 		</div> 

	// 	);
	// }, [filterText, resetPaginationToggle]);


	const contextActions = useMemo(() => {
		const handleDelete = () => {
			
			if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.full_name)}?`)) {
				setToggleCleared(!toggleCleared);
			}
		};

		return (
			<button key="delete" className='btn btn-danger' onClick={handleDelete} >
				Delete
			</button>
		);
	}, [data, selectedRows, toggleCleared]);
	

    useEffect(() => {
		dispatch(getFees(auth))

        axios
        .get(`${process.env.API_URL}/refeeral`)
        .then((res) => {
            setdata(res.data)
          console.log(res.data, "refeeral stausdata");
        })
        .catch((err) => {
          console.log(err, "here");
        });
				
	},[]);




	const handlestatus =(row)=>{
		
		
		setstatus(row)
				
		}

		
        const columns = [
            {
                name: "Reference Name",
                selector: (row) => row.referencename,
                sortable: true,
            },
            {
              name: "Refeeral Code",
              selector: (row) => row.refeeralcode,
              sortable: true,
            },
            {
                name: "Referral Name",
                selector: (row) => row.fullname,
                sortable: true,
              },
            {
              name: "Referral Number",
              selector: (row) => row.number,
              sortable: true,
            },
            {
                name: "Referral Email",
                selector: (row) => row.email,
                sortable: true,
              },

              {
                name: "Accept by",
                selector: (row) => row.accept_by,
                sortable: true,
              },
            
            {
              name: "Status",
              selector: (row) => (
                <div className="text-end">
                  {row.refeeralstatus==="withdrawal" ? (
                    <span className="badge badge-warning">pending</span>
                  ) : (
                    <span className="badge badge-success">paid</span>
                  )}
                </div>
              ),
              sortable: true,
            },
            {
              name: "Action",
              cell: (row) => (
                <div className="actions">
                  <button
                    onClick={(e) => {
                      handlestatus(row);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#referralmodal"
                    className="btn btn-sm bg-success-light ms-2"
                  >
                    <i className="fas fa-pen"></i>
                  </button>
                  {/* <a href="#" className="btn btn-sm bg-danger-light">
                    <i className="fas fa-trash"></i>
                  </a> */}
                </div>
              ),
            },
          ];
          

    return (
<>
		{/* <!-- Main Wrapper --> */}
		<div>
			
			<ReferralModal status={status} setstatus={setstatus} />

			{/* <!-- Page Wrapper --> */}
			<div className="page-wrapper">
                <div className="content container-fluid">
					 
					{/* <!-- Page Header --> */}
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Referrals List</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
									<li className="breadcrumb-item active">Referrals</li>
								</ul>
							</div>
							<div className="col-auto text-end float-left mr-auto">
								<a href="#" className="btn btn-outline-primary ms-2"><i className="fas fa-download"></i> Download</a>
								<Link to="/add-fees-collection" className="btn btn-primary"><i className="fas fa-plus"></i></Link>
							</div>
						</div>
					</div>
					{/* <!-- /Page Header --> */}
				
					<div className="row">
						<div className="col-sm-12">
						
							<div className="card card-table">
								<div className="card-body">
									<div className="table-responsive">

									<DataTable
                                         title="Referrals List"
                                         		columns={columns}
												contextActions={contextActions}
												onSelectedRowsChange={handleRowSelected}
												clearSelectedRows={toggleCleared}
												pagination
												highlightOnHover
												data={myreferralsdata}
												paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
												subHeader
												// subHeaderComponent={subHeaderComponentMemo}
												persistTableHead
												fixedHeader
  												fixedHeaderScrollHeight="400px"
										 
                                         />

										
									</div>
								</div>
							</div>							
						</div>					
					</div>					
				</div>

				{/* <!-- Footer --> */}
				<footer>
					<p>Copyright © 2023 Ifastacademy.</p>					
				</footer>
				{/* <!-- /Footer --> */}

			</div>
			{/* <!-- /Page Wrapper --> */}

		</div>
		{/* <!-- /Main Wrapper --> */}
</>
    );
}

export default Refeeralstatus;
