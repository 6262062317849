import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import coin from "../assets/img/gifts/coin.png";
import WithdrawalModal from "./refeeralModal/WithdrawalModal";
import PointsModal from "./refeeralModal/PointsModal";
import { getReferrals } from "../Redux/action/referralsAction";

export default function MyReferralsdata() {
  const auth = useSelector((state) => state.authReducer);
  const myreferralsdata = useSelector((state) => state.referralsReducer.data);
  let totalPoints = 0;
  myreferralsdata.forEach(referral => {
    totalPoints += parseInt(referral.points);
  });
  const [myrefeeraldata, setmyrefeeraldata] = useState([]);
  const [withdrawaldata, setwithdrawaldata] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handlewithdrawal = (card) => {
    setwithdrawaldata(card);
  };

  useEffect(() => {
    if(auth.username!==null){
    dispatch(getReferrals(auth))
  }
  }, [auth]);

  

  return (
    <div>
      <WithdrawalModal
        withdrawaldata={withdrawaldata}
        setwithdrawaldata={setwithdrawaldata}
      />
      <PointsModal
      withdrawaldata={withdrawaldata}
      setwithdrawaldata={setwithdrawaldata}
      />

      {/* <!-- Page Wrapper --> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <!-- Page Header --> */}
          <div className="shadow-sm rounded p-2" style={{width:"100px"}}>
            <img src={coin} alt="" style={{marginRight:"10px"}} />
            {totalPoints}</div>
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">My Referrals</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">My Referrals</Link>
                  </li>
                  <li className="breadcrumb-item active">Referrals</li>
                </ul>
              </div>
              <div
                onClick={() => {
                  navigate("/myReferrals/add");
                }}
                className="col-auto text-end float-left mr-auto"
              >
                <a className="btn btn-outline-primary ms-2">
                  <i className="fas fa-plus"></i> ADD REFERRAL
                </a>
              </div>
            </div>
          </div>
          {/* <!-- /Page Header --> */}

          <div
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "space-between",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            <div className="col-sm-4">
              <div className="step text-center">
                <img
                  src="https://i.pinimg.com/originals/fd/2c/1a/fd2c1a96b654e220d09525f006482477.gif"
                  alt="Small Gift"
                  className="gift-image w-25"
                />
                <span className="badge bg-info p-2">500 points</span>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="step text-center">
                <img
                  src="https://i.pinimg.com/originals/46/98/2b/46982b04f8911d112b6e3ce89abde8e6.gif"
                  alt="Medium Gift"
                  className="gift-image w-25"
                />
                <span className="badge bg-secondary p-2">1000 points</span>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="step  text-center">
                <img
                  src="https://i.pinimg.com/564x/92/d9/88/92d988829e5f5bf2c635d651e6568cdd.jpg"
                  alt="Large Gift"
                  className="gift-image w-25"
                />
                <span className="badge bg-success p-2">1500 points</span>
              </div>
            </div>
          </div>
          <div className="row">
            
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="container">
                      <div className="row">
                        {myreferralsdata?.map((card, index) => (
                          <>
                            <div key={index} className="col-sm-6 mb-3 mb-sm-0">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">
                                    Name: {card.fullname}
                                  </h5>
                                  <p className="card-text">
                                    Email: {card.email}
                                  </p>
                                  <p className="card-text">
                                    Phone: {card.number}
                                  </p>
                                  <div
                                    className="progress"
                                    role="progressbar"
                                    aria-label="Default striped example"
                                    aria-valuenow={
                                      card.status === "3"
                                        ? "100"
                                        : card.status === "2"
                                        ? "50"
                                        : card.status === "1"
                                        ? "25"
                                        : "0"
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    <div
                                      className={`progress-bar progress-bar-striped w-${
                                        card.status === "3"
                                          ? "100"
                                          : card.status === "2"
                                          ? "50"
                                          : card.status === "1"
                                          ? "25"
                                          : "0"
                                      }`}
                                      style={{
                                        width: `${
                                          card.status === "3"
                                            ? "100%"
                                            : card.status === "2"
                                            ? "50%"
                                            : card.status === "1"
                                            ? "25%"
                                            : "0%"
                                        }`,
                                      }}
                                    >
                                      {card.status === "3"
                                        ? "100%"
                                        : card.status === "2"
                                        ? "50%"
                                        : card.status === "1"
                                        ? "25%"
                                        : "0%"}
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <span className="text-sm">25%😀</span>
                                    <span>50%🤤</span>
                                    <span>100%🤑</span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        backgroundColor: "teal",
                                        color: "white",
                                        padding: "3px",
                                        borderRadius: "5px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {" "}
                                      {card.refeeralstatus=== "success" ? `The money withdrawal was completed successfully.` 
                                        : card.refeeralstatus==="points" &&  card.status==="3" ? `🎉 Congratulations! 🎉
                                        You've earned 100 points from this referral! 🌟 Well done! 🌟`
                                        : card.status === "1"
                                        ? `25% Hooray! ${card.fullname} has successfully secured admission.`
                                        : card.status === "2"
                                        ? `50% Hooray! Your ${card.fullname} admission has been successfully confirmed.`
                                        : card.status === "3" 
                                        ? `100% Please proceed with the withdrawal process and enjoy your funds!`
                                        : `0% Your referral has been sent to ${card.fullname} successfully.`}
                                    </span>
                                  </div>

                                 {card.refeeralstatus==="pending" && card.status==="3" ? <> <div>

                                        <span
                                          onClick={(e) => handlewithdrawal(card)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#withdrawalmodal"
                                          style={{
                                            backgroundColor: "blue",
                                            color: "white",
                                            padding: "3px",
                                            borderRadius: "2px",
                                            fontSize: "12px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Withdrawal
                                        </span>
                                        <span
                                          onClick={(e) => handlewithdrawal(card)}
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            padding: "3px",
                                            borderRadius: "2px",
                                            fontSize: "12px",
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target="#pointsmodal"
                                        >
                                          Get Points
                                        </span>
                                        </div></> : ""}

                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Footer --> */}
        <footer>
          <p>Copyright © 2023 Ifastacademy.</p>
        </footer>
        {/* <!-- /Footer --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </div>
  );
}
