import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

export default function AddReferrals() {
  const [copyText, setCopyText] = useState("");
  const [referralcode, setreferralcode] = useState("");
  const [userid, setuserid] = useState("");
  const [refeeraldata, setrefeeraldata] = useState({});
  const [message, setmessage] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  const auth = useSelector((state) => state.authReducer);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText("");
    }, 1000);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const originalString = auth.username;
    if (originalString) {
      const convertedString = originalString
        .replace("/", "")
        .replace("/", "")
        .replace("IFAST", "")
        .concat("fb");
      setuserid(convertedString);
    }
  }, [auth]);

  useEffect(() => {
    // set(ciphertext);
  }, [userid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setrefeeraldata({
      ...refeeraldata,
      [name]: value,
      ["refeeralcode"]: userid,
      ["refeeralurl"]: `https://dashboard.ifastacademy.com/referral/${userid}?fullname=${refeeraldata.fullname}&number=${refeeraldata.number}&email=${refeeraldata.email}`,
      ["userId"]: auth._id,
      ["referencename"]: auth.name,

    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(`${process.env.API_URL}/refeeral`, refeeraldata)
      .then((res) => {
        setmessage(res.data)
        if(res.data.success==true){
        setTimeout(() => {
          navigate('/myReferrals')
          setIsLoading(false);
        }, 3000);
      }
      })
      .catch((err) => {
        console.log(err,"here");
        
      });

      setTimeout(() => {
        setmessage({})
        setIsLoading(false);
      }, 3000);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <!-- Page Header --> */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">My Referrals</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">My Referrals</Link>
                  </li>
                  <li className="breadcrumb-item active">Fees List</li>
                </ul>
              </div>
              <div
                onClick={() => {
                  navigate("/myReferrals/add");
                }}
                className="col-auto text-end float-left mr-auto"
              >
                <a className="btn btn-outline-primary ms-2">
                  <i className="fas fa-plus"></i> ADD REFERRAL
                </a>
              </div>
            </div>
          </div>
          {/* <!-- /Page Header --> */}

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div>
                    <div className="input-group mb-3">
                    {isLoading && message.message!="" ? <><div className={`alert alert-${message.success ? 'success' : 'danger'}`} role="alert">
                        <strong>Meaage!</strong> {message.message}
                      </div></>: ""}
                      <form onSubmit={handleSubmit} className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                          <label>Full Name</label>
                          <input
                            type="text"
                            name="fullname"
                            className="form-control"
                            placeholder="Full Name"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                          <label>Email</label>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                          <label>Phone Number</label>
                          <input
                            type="number"
                            name="number"
                            className="form-control"
                            placeholder="Phone Number"
                            onChange={handleChange}
                            required
                            min="1000000000"
                            max="9999999999"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                       
                        <div>
                            <button
                              className={`btn btn-primary${isLoading ? ' loading' : ''}`}
                              type="submit"
                            >
                              {isLoading ? (
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                'Submit'
                              )}
                              {isLoading && <span className="loading-text">Loading...</span>}
                            </button>
                          </div>
                        </div>
                      </form>
                     
                      <div className="alert alert-primary" role="alert">
                        "🤑 Earn money by referring your friends to our academy!
                        🙌 Fill out our referral form and share your unique
                        code. 📲 Your friends will receive a discount and you'll
                        earn a commission when they enroll in our courses. 💸
                        Start referring today!"
                      </div>

                      
                    </div>

                    <div className="mb-3">
                      <label htmlFor="basic-url" className="form-label mt-5">
                        <h5>Share with your friends</h5>
                      </label>
                      <div className="">
                        <div className="input-group d-flex mb-3 w-md-75">
                          <span className="input-group-text" id="basic-addon3">
                          https://dashboard.ifastacademy.com/referral/
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={userid}
                            id="basic-url"
                            aria-describedby="basic-addon3"
                          />
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            className="input-group-text courser"
                            id="basic-addon3"
                            onClick={() =>
                              handleCopy(
                                `https://dashboard.ifastacademy.com/referral/${userid}`
                              )
                            }
                          >
                            <i className="fas fas fa-clipboard ml-5"></i>
                            Copy
                          </span>
                        </div>
                        {copyText && <p>Copy</p>}
                        <div className="input-group d-flex w-md-50 ">
                          <span className="input-group-text" id="basic-addon3">
                            Referral Code:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="basic-url"
                            value={userid}
                            aria-describedby="basic-addon3 basic-addon4"
                          />
                          <span
                            className="input-group-text"
                            id="basic-addon3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => handleCopy(userid)}
                          >
                            <i className="fas fas fa-clipboard ml-5"></i>
                            Copy
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Footer --> */}
        <footer>
          <p>Copyright © 2023 Ifastacademy.</p>
        </footer>
        {/* <!-- /Footer --> */}
      </div>
    </div>
  );
}
