import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Login from "./authentication/login";

const cookies = new Cookies();

export default function ProtectedRoutesAdmin(props) {
  const { Components } = props;
  const token = cookies.get("ifastacademy-token");

  const auth = useSelector((state) => state.authReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.role != null) {
      if (auth.role === "Admin") {
      } else {
        navigate("/studentdashboard");

        toast.error("You do not have permission to access this page.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }, [auth]);

  return <div>{token ? <Components /> : <Login />}</div>;
}
