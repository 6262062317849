import React from "react";
import { useState } from "react";
import Avatar from "react-avatar-edit";
import { useDispatch } from "react-redux";
import { PatchAdmission, postStudents } from "../Redux/action/studentAction";
import Select2 from "react-select2-wrapper";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCourse } from "../Redux/action/courseAction";
import { useEffect } from "react";

export default function EnquiryModal({ newdata, setnewdata }) {
  const auth = useSelector((state) => state.authReducer);
	const datacourse = useSelector((state)=>state.courseReducer.data) 


  useEffect(()=>{
    dispatch(getCourse())
  
    },[])

  const dispatch = useDispatch();
  const handlechange = (e) => {
    if(e.target.name == "callremark") {
      setnewdata({
        ...newdata,
        [e.target.name]: e.target.value,
        ["calldate"]: Date(),
      });
    } else if (e.target.name == "status") {
      setnewdata({
        ...newdata,
        ["statusdate"]: Date(),
        [e.target.name]: e.target.value,
      });
    } else {
      setnewdata({
        ...newdata,
        [e.target.name]: e.target.value,
      });
    }
  };


  const handleSubmit = (e) => {
      e.preventDefault();
    if(newdata.status == "Join"){
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var checkemail =  emailPattern.test(email)
        if(checkemail){
          axios
          .post(`${process.env.API_URL}/register/student`, newdata, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res)=>{
  
            // user created when status is join with valid validation 
           dispatch(PatchAdmission(newdata, newdata._id, auth));
  
          })
  
          .catch((error) => {
            toast.error(error.response?.data, {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
        }
        else{

          toast.error("please Add Email Address", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

        }
     
    } else {

      dispatch(PatchAdmission(newdata, newdata._id, auth));
    }
  };
  

  const {
    callstatus,
    callremark,
    course,
    date,
    email,
    fullname,
    number,
    occupation,
    qualificaton,
    status,
    statusdate,
    statusremark,
    _id,
  } = newdata;
  return (
    <div
      className="modal fade"
      id="enquirymodal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Enquiry Update
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Full Name</label>
                <div className="col-lg-9">
                <input
                     onChange={handlechange}
                     type="text"
                     name="fullname"
                     value={fullname || ''}
                     className="form-control"
                     required
                    />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Mobile Number</label>
                <div className="col-lg-9">
                <input
                     onChange={handlechange}
                     type="number"
                     name="number"
                     value={number || ''}
                     className="form-control"
                     required
                    />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Email Address</label>
                <div className="col-lg-9">
                <input
                     onChange={handlechange}
                     type="text"
                     name="email"
                     value={email || ''}
                     className="form-control"
                    />
                </div>
              </div>

              <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Coures</label>
                        <span className="col-lg-9">
                          <select
                            onChange={handlechange}
                            className="form-select"
                            aria-label="Default select example"
							              name="course"
                            required
                            value={course}
                          >
                            <option value='' defaultValue={"Select"}>
                              Select Course
                            </option>
							            {datacourse?.map((e)=>(

                          <option key={e._id} value={e.course}>{e.course}</option>
                          ))}
                                      
                          </select>
                        </span>
                </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Status</label>
                <div className="col-lg-9">
                  <select
                    value={status}
                    onChange={handlechange}
                    name="status"
                    className="form-control"
                    
                  >
                    <option>Join</option>
                    <option>Pending</option>
                    <option>Cancel</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Status Remark</label>
                <div className="col-lg-9">
                  <textarea
                    onChange={handlechange}
                    name="statusremark"
                    value={statusremark}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              
              data-bs-dismiss="modal"
              aria-label="Close"
              type="submit"
              className="btn btn-primary"
            >
              Save changes
            </button>
          </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
