
export const GET_FEES="GET_FEES"
export const  FIND_FEES_LIST = "FIND_FEES_LIST"
export const CLEAR_FEES = "CLEAR_FEES"


export const feesReducer = (store={data:[]},{type,payload})=>{
    switch(type){
     case GET_FEES:
         return {...store,data:payload}
     case FIND_FEES_LIST:
         return {...store,data:payload}
    case "CLEAR_FEES": 
        return {...store=[],data:[]}
      
         default:
             return store
    }
}

export default feesReducer;
 