import React from "react";
import Header from "../header";
import Sidebar from "../sidebar";
import ApexCharts from "apexcharts";
import proPic from "../assets/img/profiles/avatar-02.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { FindUser, postStudents } from "../Redux/action/studentAction";
import { useState } from "react";
import ProfileModal from "./profileModal";
import Select2 from "react-select2-wrapper";
import profile_avatar from "../assets/img/profiles/profile_avatar.jpg";
import { ChangePassword } from "../Redux/action/authAction";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getCourse } from "../Redux/action/courseAction";
import ChangeNameModal from "./changeNameModal";
import ChangeEmali from "./changeEmali";
import axios from "axios";
import PDFViewer from "./PdfViewer";
import PdfViewer from "./PdfViewer";

const Profile = () => {
  const [data, setData] = useState([]);
  const [formState, setFormState] = useState({});
  const [statusdisable, setStatusdisable] = useState(true);
  const [passwordInput, setPasswordInput] = useState(true);
  const [certificatedata, setCertificatedata] = useState({});

  const auth = useSelector((state) => state.authReducer);
  const finduser = useSelector((state) => state.studentReducer.data);
  const course = useSelector((state) => state.courseReducer.data);

  useEffect(() => {
    const headers = { Authorization: `Bearer ${auth.token}` };

    axios
      .get(
        `${process.env.API_URL}/studentstatus/studentcertificate/${auth._id}`,
        { headers }
      )
      .then((response) => {
        setCertificatedata(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [auth]);

  const dispatch = useDispatch();
  const [options, setOptions] = useState([
    { id: "10th", text: "10th" },
    { id: "12th", text: "12th" },
    { id: "(Ug) Undergraduate", text: "Undergraduate" },
    { id: "(Pg) Postgraduate", text: "Postgraduate" },
  ]);

  const [option1, setOptions1] = useState([
    { id: "Student", text: "Student" },
    { id: "Working", text: "Working" },
  ]);

  const [option2, setOptions2] = useState([
    { id: "Male", text: "Male" },
    { id: "Female", text: "Female" },
  ]);

  useEffect(() => {
    dispatch(getCourse());
    setData(finduser);
  }, [finduser]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      ["student_name"]: auth.name,
      ["student_email_id"]: auth.email,
      ["student_id"]: auth.username,
    });
  };

  function handlePassword(e) {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      ["userlogin"]: auth.username,
    });
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (formState.newpassword != formState.confirmpassword) {
      // console.log(formState)
      toast.warn(`both passwords are not matching`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const data = {
        userlogin: formState.userlogin,
        newpassword: formState.newpassword,
        oldpassword: formState.oldpassword,
      };

      dispatch(ChangePassword(data));
    }
  };

  const {
    student_address,
    student_city,
    student_course,
    student_date_of_birth,
    student_father_name,
    student_gender,
    student_mobile_number,
    student_occupation,
    student_parent_mobile_number,
    student_pin_code,
    student_qualification,
  } = data;

  const pdfUrl =
    "https://drive.google.com/file/d/1ONCbH1G9W3gOh6QzjmTZ7BS33NE-xAqS/view";

  return (
    <div>
      <ProfileModal data={data} setData={setData} auth={auth} />
      bnm 7 [piut45 ]
      <ChangeNameModal />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Profile</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="profile-header">
                <div className="row align-items-center">
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#profilemodal"
                    className="col-auto profile-image"
                  >
                    <a>
                      <img
                        className="rounded-circle"
                        alt="User Image"
                        src={
                          finduser.userimg != undefined
                            ? finduser.userimg
                            : profile_avatar
                        }
                      />
                    </a>
                  </div>
                  <div className="col me-md-n2 profile-user-info">
                    <h4 className="user-name mb-0">{auth.name}</h4>
                    <h6 className="text-muted">{auth.username}</h6>
                    <div className="user-Location">
                      <i className="fas fa-map-marker-alt"></i> New Delhi, India
                    </div>
                    <div className="about-text">IFASTACADEMY</div>
                  </div>
                  {/* <div className="col-auto profile-btn">
                    <a data-bs-toggle="modal"
                    data-bs-target="#changename" className="btn btn-primary">Edit</a>
                  </div> */}
                </div>
              </div>
              <div className="profile-menu">
                <ul className="nav nav-tabs nav-tabs-solid">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#per_details_tab"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#password_tab"
                    >
                      Password
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#email_tab"
                    >
                      Email
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#certificate_tab"
                    >
                      Certificate
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content profile-tab-cont">
                <div className="tab-pane fade show active" id="per_details_tab">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title d-flex justify-content-between ">
                            <span>Personal Detail's</span>
                            <a
                              className="edit-link"
                              onClick={() =>
                                setStatusdisable(
                                  (statusdisable) => !statusdisable
                                )
                              }
                            >
                              <i className="far fa-edit me-1"></i>Edit
                            </a>
                          </h5>

                          <div className="card-body">
                            <div className="form-row row">
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer01">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationServer01"
                                  placeholder="Full Name"
                                  value={auth.name || ""}
                                  disabled
                                  required
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer02">
                                  Email ID
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationServer02"
                                  placeholder="Email ID"
                                  value={auth.email || ""}
                                  disabled
                                  required
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer03">
                                  Date of Birth
                                </label>
                                <input
                                  onChange={(e) => handleChange(e)}
                                  type="date"
                                  className="form-control"
                                  id="validationServer03"
                                  placeholder="Date of Birth"
                                  required
                                  disabled={statusdisable}
                                  value={student_date_of_birth || ""}
                                  name="student_date_of_birth"
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer04">
                                  Qualification
                                </label>
                                <Select2
                                  required
                                  className="form-control"
                                  value={student_qualification || ""}
                                  name="student_qualification"
                                  disabled={statusdisable}
                                  onChange={(e) => handleChange(e)}
                                  data={options}
                                  options={{
                                    placeholder: "Select",
                                  }}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer05">
                                  Mobile No.
                                </label>
                                <input
                                  onChange={(e) => handleChange(e)}
                                  type="number"
                                  className="form-control"
                                  id="validationServer05"
                                  placeholder="Mobile No."
                                  value={student_mobile_number || ""}
                                  name="student_mobile_number"
                                  disabled={statusdisable}
                                  required
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer06">
                                  Course Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationServer02"
                                  placeholder="Course Name"
                                  value={student_course || ""}
                                  disabled
                                  title={student_course || ""}
                                  required
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer07">City</label>
                                <input
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  className="form-control"
                                  id="validationServer07"
                                  placeholder="City"
                                  value={student_city || ""}
                                  name="student_city"
                                  required
                                  disabled={statusdisable}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer08">
                                  Father's Name
                                </label>
                                <input
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  className="form-control"
                                  id="validationServer08"
                                  placeholder="Father's Name"
                                  value={student_father_name || ""}
                                  name="student_father_name"
                                  required
                                  disabled={statusdisable}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer09">
                                  Gender
                                </label>
                                <Select2
                                  required
                                  id="validationServer9"
                                  className="form-control"
                                  value={student_gender || ""}
                                  name="student_gender"
                                  disabled={statusdisable}
                                  onChange={(e) => handleChange(e)}
                                  data={option2}
                                  options={{
                                    placeholder: "Select",
                                  }}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer10">
                                  Current Status
                                </label>
                                <Select2
                                  required
                                  id="validationServer10"
                                  className="form-control"
                                  value={student_occupation || ""}
                                  name="student_occupation"
                                  disabled={statusdisable}
                                  onChange={(e) => handleChange(e)}
                                  data={option1}
                                  options={{
                                    placeholder: "Select",
                                  }}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer11">
                                  Emergency Contact No.
                                </label>
                                <input
                                  onChange={(e) => handleChange(e)}
                                  type="number"
                                  className="form-control"
                                  id="validationServer11"
                                  placeholder="Mobile No."
                                  value={student_parent_mobile_number || ""}
                                  name="student_parent_mobile_number"
                                  required
                                  disabled={statusdisable}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer12">
                                  Pin Code
                                </label>
                                <input
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  className="form-control"
                                  id="validationServer12"
                                  placeholder="Pin Code"
                                  value={student_pin_code || ""}
                                  name="student_pin_code"
                                  required
                                  disabled={statusdisable}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationServer13">
                                  Full Address
                                </label>
                                <input
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  className="form-control"
                                  id="validationServer13"
                                  placeholder="Address"
                                  value={student_address || ""}
                                  name="student_address"
                                  required
                                  disabled={statusdisable}
                                />
                              </div>
                            </div>
                            <button
                              disabled={statusdisable}
                              onClick={() => {
                                dispatch(postStudents(data, auth));
                                setStatusdisable(true);
                              }}
                              className="btn btn-success"
                              type="button"
                            >
                              Update Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title d-flex justify-content-between">
                            <span>Account Status</span>
                            <a className="edit-link" href="#">
                              <i className="far fa-edit me-1"></i> Edit
                            </a>
                          </h5>
                          <button className="btn btn-success" type="button">
                            <i className="fe fe-check-verified"></i> Active
                          </button>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title d-flex justify-content-between">
                            <span>Skills </span>
                            <a className="edit-link" href="#">
                              <i className="far fa-edit me-1"></i> Edit
                            </a>
                          </h5>
                          <div className="skill-tags">
                            <span>Html5</span>
                            <span>CSS3</span>
                            <span>WordPress</span>
                            <span>Javascript</span>
                            <span>Android</span>
                            <span>iOS</span>
                            <span>Angular</span>
                            <span>PHP</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div id="password_tab" className="tab-pane fade">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Change Password</h5>
                      <div className="row">
                        <div className="col-md-10 col-lg-6">
                          <form onSubmit={handlePasswordSubmit}>
                            <div className="col-lg-12">
                              <label>Old Password</label>
                              <div className="input-group">
                                <input
                                  onChange={handlePassword}
                                  type={passwordInput ? "password" : "text"}
                                  name="oldpassword"
                                  className="form-control"
                                  placeholder="Password"
                                  required
                                />

                                <span className="input-group-text">
                                  <i
                                    onClick={() =>
                                      setPasswordInput((curr) => !curr)
                                    }
                                    className={
                                      passwordInput
                                        ? "fas fa-eye-slash"
                                        : "fas fa-eye"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                            <div className="foz rm-group">
                              <label>New Password</label>
                              <input
                                onChange={handlePassword}
                                type="text"
                                name="newpassword"
                                required
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <input
                                onChange={handlePassword}
                                type="text"
                                name="confirmpassword"
                                required
                                className="form-control"
                              />
                            </div>
                            <button className="btn btn-primary" type="submit">
                              Save Changes
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ChangeEmali />
                <div id="certificate_tab" className="tab-pane fade">
                  <div className="card">
                    <div className="card-body">
                      {/* <h5 className="card-title">Download Certificate</h5> */}
                      <h5 className="card-title">
                        {certificatedata.certificate != "" &&
                        certificatedata.status == "Certified"
                          ? "Download Certificate"
                          : "Your Not Certified"}
                      </h5>

                      <div className="row">
                        {certificatedata.certificate != "" &&
                        certificatedata.status == "Certified" ? (
                          <PdfViewer Url={certificatedata.certificate} />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
