export const  GET_USERS="GET_USERS"
export const GET_OTP = "GET_OTP"

import axios  from "axios"
import { toast } from "react-toastify"

// import { setHeaders } from "../../api"

export const usersdata=(data)=>{
    return {
        type:GET_USERS,
        payload:data
    }
}




export const otpsection=(otp)=>{
    return {
        type:GET_OTP,
        payload:otp
    }
}


export const getusers=(auth)=>{
    return async(dispatch, getState, api) => {
         try {
             
             fetch(`${process.env.API_URL}/users`, {
              method: "GET",
              headers: {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
              }
            })
            .then(response => response.json())
            .then(data => dispatch(usersdata(data)))
            
         } 
         catch (error) {
            console.log(error)
         }
      }
}

export const getStudentStatusData=(status,auth)=>{
  return async(dispatch, getState, api) => {
       try {  
           let res=await fetch(`${process.env.API_URL}/studentstatus/${status}`,{
            headers: {
              "Authorization": `Bearer ${auth.token}`,
              "Content-Type": "application/json"
            },
           })
           let data=await res.json()
           console.log(data)
           dispatch(usersdata(data))
       } 
       catch(error){
          console.log(error)
       }
    }
}


 export const ChangeEmail = (data) => {
        return async (dispatch) => {    
            try {
              await axios
                .post(`${process.env.API_URL}/users/changeemail`, data)
                .then((e) => {
                    toast.success(e.data, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                        
                })
                .catch((error) => {
                    toast.error(error.response?.data, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                  console.log(error.response?.data);
                  
                });
            } catch (err) {
              console.log(err.data,"hellooo");
            }
          };
        };

        export const ChangeName = (data) => {
          
            return async (dispatch) => {    
                try {
                  await axios
                    .post(`${process.env.API_URL}/users/name`, data)
                    .then((e) => {
                      
                        toast.success(e.data, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });

                            dispatch({
                                type: "SIGN_OUT",
                              });
                     
                    })
                    .catch((error) => {
                        toast.error(error.response?.data, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });
                      console.log(error.response?.data);
                      
                    });
                } catch (err) {
                  console.log(err.data,"hellooo");
                }
              };
            };
    
    




export const PostOtp = (data) => {
        return async (dispatch) => {    
                try {
                  await axios
                    .post(`${process.env.API_URL}/users/otp`, data)
                    .then((e) => {
                      
                        toast.success(e.data, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });
                            dispatch({
                                type: "SIGN_OUT",
                              });
                          
                     
                    })
                    .catch((error) => {
                        toast.error(error.response?.data, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });
                      console.log(error.response?.data);
                      
                    });
                } catch (err) {
                  console.log(err.data,"hellooo");
                }
              };
            };
    
        
    


// export const postStudents = (data, id) => {
//     return async (dispatch) => {    
//         try {
//           await axios
//             .post(`${process.env.API_URL}/users${id}`, data)
//             .then((e) => {
//               dispatch(getusers());
//             })
//             .catch((error) => {
//               console.log(error.response?.data);
//               alert(error.response?.data);
//             });
//         } catch (err) {
//           console.log(err);
//         }
//       };
//     };


    export const PatchUsers=(data,id)=>{
        return async(dispatch, getState, api) => {
            try {
                await fetch(`${process.env.API_URL}/users/${id}`,{
                        method:"PATCH",
                        body:JSON.stringify(data),
                        
                        headers:{
                            'Content-Type': 'application/json'
                        }
                    })
                    dispatch(getusers())
                
            } 
            catch (error) {
                console.log(error)
            }
    }
    }


    

    
