
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { PostCourse } from '../Redux/action/courseAction';

const AddCourse = () => {

	const [Course, SetCourse] = useState()
	const dispatch  = useDispatch()

	const  handlesubmit = (e) =>{
		
		e.preventDefault();
		dispatch(PostCourse(Course))
		


	}

	const handleChange = (e) =>{
		const {name , value}= e.target;

		SetCourse({
			...Course,
			[name]: value,
		});
	}
   
    return (

		<div >
		
			<div className="page-wrapper">
                <div className="content container-fluid">

					{/* <!-- Page Header -->*/}
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Add Course</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/course">course</Link></li>
									<li className="breadcrumb-item active">Add Course</li>
								</ul>
							</div>
						</div>
					</div>	
					{/* <!-- /Page Header --> */}

					<div className="row">
						<div className="col-sm-12">
						
							<div className="card">
								<div className="card-body">
									<form onSubmit={handlesubmit}>
										<div className="row">
											<div className="col-12">
												<h5 className="form-title"><span>Course Information</span></h5>
											</div>
											<div className="col-12 col-sm-6">  
												<div className="form-group">
													<label>Course Name</label>
													<input onChange={handleChange} type="text" name="course" className="form-control" required/>
												</div>
											</div>
											
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Fee</label>
													<input onChange={handleChange} type="number" name="coursefees" className="form-control" required/>
												</div>
											</div>
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Duration</label>
													<input onChange={handleChange} type="text" name="duration" className="form-control" required/>
												</div>
											</div>
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Programs</label>
													<input onChange={handleChange} name="programs" type="text" className="form-control" required/>
												</div>
											</div>
											<div className="col-12 col-sm-6">
												<div className="form-group">
													<label>Course Description</label>
													<textarea name="description" onChange={handleChange} type="text" className="form-control" required />
													
												</div>
											</div>
											
											<div className="col-12">
												<button type="submit" className="btn btn-primary">Submit</button>
											</div>
										</div>
									</form>
								</div>
							</div>
							
						</div>					
					</div>					
				</div>				
			</div>
			{/* <!-- /Page Wrapper --> */}

		</div>
		// <!-- /Main Wrapper -->

    );
}

export default  AddCourse;
