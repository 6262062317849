
import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../assets/img/logo-white.png';
import Loginbg from '../assets/img/login.jpg';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../Redux/action/authAction';
import { useEffect } from 'react';





const Login = () => {

	const dispatch = useDispatch()
	const auth = useSelector((state) => state.authReducer)
	const [passwordInput, setPasswordInput] = useState(true);
	const navigate = useNavigate()
	
	const [formState, setFormState] = useState({})

	function handleChange(e) {
		const { name, value } = e.target
		setFormState({
		  ...formState,
		  [name]: value,
		});
	  }

	const handleSubmit = (e)=>{
		e.preventDefault();
		dispatch(signIn(formState))

		}
	
		
		
		useEffect(()=>{
			if(auth.token && auth.role=='Admin'){
				navigate('/dashboard')
			}
			else if(auth.token && auth.role=="student"){
				navigate('/studentdashboard')
			}
			else {
				navigate('/login')
			}

	},[auth])


    return (
<>
        {/* Main Wrapper */}
        <div className="main-wrapper login-body">
            <div className="login-wrapper">
            	<div className="container">
                	<div className="loginbox">
                    	<div className="login-left" style={{ backgroundImage: 'url('+Loginbg+')', }} >					
							<img className="img-fluid" src={Logo} alt="Logo"/>
                        </div>
                        <div className="login-right">
							<div className="login-right-wrap">
								<h1>Login</h1>
								<p className="account-subtitle">Access to our dashboard</p>
								
								{/* <!-- Form --> */}
									<form onSubmit={handleSubmit}>
									<div className="form-group">
										<input onChange={handleChange} autoComplete="email" className="form-control" name='userlogin' type="text"  required placeholder="Student ID or Email"/>
									</div>
									<div className="input-group mb-5">
                                <input
                                  onChange={handleChange}
                                  type={passwordInput ? "password" : "text"}
                                  name="password"
								  autoComplete="password"
                                  className="form-control"
                                  placeholder="Password"
                                  required
                                />

                                <span className="input-group-text">
                                  <i
                                    onClick={() =>
                                      setPasswordInput((curr) => !curr)
                                    }
                                    className={
                                      passwordInput
                                        ? "fas fa-eye-slash"
                                        : "fas fa-eye"
                                    }
                                  ></i>
                                </span>
                              </div>
									<div className="form-group">
										<button className="btn btn-primary btn-block"  type='submit'>Login</button>
									</div>
									</form>
								{/* <!-- /Form --> */}
								
								<div className="text-center forgotpass"><Link to="/forgotpassword">Forgot Password?</Link></div>
								{/* <div className="login-or">
									<span className="or-line"></span>
									<span className="span-or">or</span>
								</div> */}
								  
								{/* Social Login */}
								{/* <div className="social-login">
									<span>Login with</span>
									<a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a><a href="#" className="google"><i className="fab fa-google"></i></a>
								</div> */}
								{/* /Social Login */}
								
								{/* <div className="text-center dont-have">Don’t have an account? <Link to="/register">Register</Link></div> */}
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       {/* /Main Wrapper */}
</>
    );
}

export default  Login;
