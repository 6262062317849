import React from "react";
import {
  BrowserRouter,
  Routes,
  Router,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

import Dashboard from "./dashboard/Admindashboard";
import Teacherdashboard from "./dashboard/teacherdashboard";
import Studentdashboard from "./dashboard/Studentdashboard";
import Login from "./authentication/login";
import Profile from "./profile/profile";
import Register from "./authentication/register";
import Salary from "./accounts/salary";
import Sports from "./sports/sports";
import Studentdetails from "./students/studentdetails";
import Forgotpassword from "./authentication/forgotpassword";
import Addbooks from "./library/addbooks";
import Adddepartment from "./departments/adddepartment";
import Addevents from "./event/addevents";
import Addexam from "./exam/addexam";
import Addexpenses from "./accounts/addexpenses";
import Addfees from "./fees/addfees";
import Addfeescollection from "./accounts/addfeescollection";
import Addholiday from "./holiday/addholiday";
import Addroom from "./hostel/addroom";
import Addsalary from "./accounts/addsalary";
import Addsports from "./sports/addsports";
import Addstudents from "./Students/addstudents";
import Addteacher from "./teachers/addteacher";
import Teacherdetails from "./teachers/teacherdetails";
import Addtimetable from "./timetable/addtimetable";
import Addtransport from "./transport/addtransport";
import Blankpage from "./blankpage/index";
import Components from "./components/index";
import Compose from "./compose/index";
import Datatable from "./datatable/index";
import Departments from "./departments/index";
import Editbooks from "./library/editbooks";
import Editdepartments from "./departments/editdepartments";
import Editevents from "./edit/editevents";
import Editexam from "./exam/editexam";
import Editfees from "./fees/editfees";
import Editroom from "./hostel/editroom";
import Editsports from "./sports/editsports";
import Editstudents from "./students/editstudents";
import Editteacher from "./teachers/editteacher";
import Edittimetable from "./timetable/edittimetable";
import Edittransport from "./transport/edittransport";
import Page404 from "./errorpage/page404";
import Event from "./event/index";
import Exam from "./exam/index";
import Expenses from "./accounts/index";
import Fees from "./fees/fees";
import Feescollection from "./accounts/feescollection";
import Holiday from "./holiday/index";
import Library from "./library/index";
import Students from "./students/index";
import Enquiry from "./students/enquiry";
import Hostel from "./hostel/index";
import Inbox from "./inbox/index";
import Formbasicinputs from "./forms/formbasicinputs";
import Formhorizontal from "./forms/formhorizontal";
import Forminputgroups from "./forms/forminputgroups";
import Formmask from "./forms/formmask";
import Formvalidation from "./forms/formvalidation";
import Formvertical from "./forms/formvertical";
import Teachers from "./teachers/teachers";
import Transport from "./transport/transport";
import Timetable from "./timetable/timetable";
import BasicTables from "./datatable/BasicTables";
import { useEffect } from "react";
import { FindUser } from "./Redux/action/studentAction";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoutes from "./ProtectedRoutes";
import FeesPending from "./Accounts/feesfalse";
import Admission from "./blankpage/Admission";
import Header from "./header";
import Sidebar from "./sidebar";
import Course from "./course/course";
import AddCourse from "./course/addcourse";
import ProtectedRoutesAdmin from "./ProtectedRoutesAdmin";
import jwtDecode from "jwt-decode";
import Attendance from "./students/attendance";
import ActiveStudent from "./students/activeStudent";
import ChatBot from "./blankpage/chatBot";
import NotUpdatedStudents from "./students/notUpdateStudent";
import MyReferrals from "./pages/MyReferrals";
import AddReferrals from "./pages/AddReferrals";
import RefeeralWithUrl from "./blankpage/RefeeralWithUrl";
import Refeeralstatus from "./pages/RefeeralStatus";
import AttendanceQuery from "./Attendance/AttendanceQuery";
import Studentsfees from "./Accounts/Studentsfees";

const Approuter = (props) => {
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FindUser(auth));
  }, [dispatch, auth]);

  var isAuth;

  try {
    isAuth = jwtDecode(auth.token);
  } catch (e) {
    isAuth = "Not Authorized";
  }

  return (
    <div className="main-wrapper">
      {auth.token != undefined ? (
        <div>
          {" "}
          <Header />
          <Sidebar />{" "}
        </div>
      ) : (
        <div></div>
      )}
      <Routes>
        <Route
          path="/"
          element={
            isAuth == "Not Authorized" ? (
              <Navigate replace to="/login" />
            ) : isAuth.role == "student" ? (
              <Navigate replace to="/studentdashboard" />
            ) : (
              <Navigate replace to="/dashboard" />
            )
          }
        />
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="/admission" element={<Admission />} />
        <Route path="/referral/:id" element={<RefeeralWithUrl />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/register"
          element={<ProtectedRoutesAdmin Components={Register} />}
        />
        <Route
          path="/profile"
          element={<ProtectedRoutes Components={Profile} />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoutesAdmin Components={Dashboard} />}
        />

        <Route
          path="/student_attandance"
          element={<ProtectedRoutesAdmin Components={AttendanceQuery} />}
        />
        {/* 
        <Route
          path="/addfees"
          element={<ProtectedRoutes Components={Addfees} />}
        /> */}
        <Route
          path="/Students/fees"
          element={<ProtectedRoutesAdmin Components={Studentsfees} />}
        />
        <Route
          path="/feeslist"
          element={<ProtectedRoutes Components={Fees} />}
        />
        <Route
          path="/chatbot"
          element={<ProtectedRoutes Components={ChatBot} />}
        />
        <Route
          path="/students"
          element={<ProtectedRoutesAdmin Components={Students} />}
        />
        <Route
          path="/enquiry"
          element={<ProtectedRoutesAdmin Components={Enquiry} />}
        />
        <Route
          path="/feescollection"
          element={<ProtectedRoutesAdmin Components={Feescollection} />}
        />
        <Route
          path="/course"
          element={<ProtectedRoutesAdmin Components={Course} />}
        />
        <Route
          path="/addcourse"
          element={<ProtectedRoutesAdmin Components={AddCourse} />}
        />
        <Route path="/feespending" element={<FeesPending />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route
          path="/studentdashboard"
          element={<ProtectedRoutes Components={Studentdashboard} />}
        />
        <Route
          path="/myReferrals"
          element={<ProtectedRoutes Components={MyReferrals} />}
        />
        <Route
          path="/myReferrals/add"
          element={<ProtectedRoutes Components={AddReferrals} />}
        />
        <Route
          path="/refeeralstatus"
          element={<ProtectedRoutesAdmin Components={Refeeralstatus} />}
        />
        <Route
          path="/studentattendance"
          element={<ProtectedRoutes Components={Attendance} />}
        />
        <Route
          path="/activestudents/:id"
          element={<ProtectedRoutesAdmin Components={ActiveStudent} />}
        />
        <Route
          path="/notUpdatedStudents"
          element={<ProtectedRoutesAdmin Components={NotUpdatedStudents} />}
        />

        {/* <Route path="/addfeescollection" element={<Addfeescollection/>} /> */}
        {/* <Route path="/tables-basic" element={<BasicTables/>} />          Q  QQ
            <Route path="/salary" element={<Salary />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/studentdetails" element={<Studentdetails />} />
            <Route path="/addbooks" element={<Addbooks/>} />
            <Route path="/adddepartment" element={<Adddepartment/>} />
            <Route path="/addevents" element={<Addevents/>} />
            <Route path="/addexam" element={<Addexam/>} />
            <Route path="/addexpenses" element={<Addexpenses/>} />
            <Route path="/addholiday" element={<Addholiday/>} />
            <Route path="/addroom" element={<Addroom/>} />
            <Route path="/addsalary" element={<Addsalary/>} />
            <Route path="/addsports" element={<Addsports/>} />
            <Route path="/addstudents" element={<Addstudents/>} />
            <Route path="/addteacher" element={<Addteacher/>} />
            <Route path="/addtimetable" element={<Addtimetable/>} />
            <Route path="/addtransport" element={<Addtransport/>} />
            <Route path="/blankpage" element={<Blankpage/>} />
            <Route path="/components" element={<Components/>} />
            <Route path="/compose" element={<Compose/>} />
            <Route path="/datatable" element={<Datatable/>} />
            <Route path="/departments" element={<Departments/>} />
            <Route path="/editbooks" element={<Editbooks/>} />
            <Route path="/editdepartments" element={<Editdepartments/>} />
            <Route path="/editevents" element={<Editevents/>} />
            <Route path="/editexam" element={<Editexam/>} />
            <Route path="/editfees" element={<Editfees/>} />
            <Route path="/editroom" element={<Editroom/>} />
            <Route path="/editsports" element={<Editsports/>} />
            <Route path="/editstudents" element={<Editstudents/>} />
            <Route path="/editteacher" element={<Editteacher/>} />
            <Route path="/edittimetable" element={<Edittimetable/>} />
            <Route path="/edittransport" element={<Edittransport/>} />
            <Route path="/event" element={<Event/>} />
            <Route path="/exam" element={<Exam/>} />
            <Route path="/expenses" element={<Expenses/>} />
            <Route path="/holiday" element={<Holiday/>} />
            <Route path="/library" element={<Library/>} />
            <Route path="/hostel" element={<Hostel/>} />
            <Route path="/inbox" element={<Inbox/>} />
            <Route path="/formbasicinputs" element={<Formbasicinputs/>} />
            <Route path="/formhorizontal" element={<Formhorizontal/>} />
            <Route path="/forminputgroups" element={<Forminputgroups/>} />
            <Route path="/formmask" element={<Formmask/>} />
            <Route path="/formvalidation" element={<Formvalidation/>} />
            <Route path="/formvertical" element={<Formvertical/>} />
            <Route path="/teacherdashboard" element={<Teacherdashboard/>} />
            <Route path="/teacherdetails" element={<Teacherdetails/>} />
            <Route path="/teachers" element={<Teachers/>} />
            <Route path="/transport" element={<Transport/>} />
            <Route path="/timetable" element={<Timetable/>} /> */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
};

export default Approuter;
