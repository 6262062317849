import { ADD_REFERRAL, GET_REFERRALS } from "../action/referralsAction";


const referralsReducer=(store={data:[]},{type,payload})=>{
    switch(type){
     case GET_REFERRALS:
         return {...store,data:payload}
         case ADD_REFERRAL:
         return {...store,data:payload}

         default:
             return store
    }
}

export default referralsReducer;
