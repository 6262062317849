import React from 'react'
import { useState } from 'react'
import Avatar from 'react-avatar-edit'
import Select2 from 'react-select2-wrapper';
import axios from 'axios';
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PostReferral,PostReferralforAdmin } from '../../Redux/action/referralsAction';


export default function ReferralModal({status,setstatus}) {
  const auth = useSelector((state) => state.authReducer);
    const dispatch = useDispatch()
  const hnadlesubmitereferral = (status) =>{

        dispatch(PostReferralforAdmin(status,auth, {refeeralstatus:"success", accept_by:auth.name}))
            
    }


  return (
    
    <div className="modal fade" id="referralmodal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Update Fee</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <span>Reference Name : {status.referencename}</span><br/>
          <span>Refeeral Code : {status.refeeralcode}</span><br/>
          <span>Referral Name : {status.fullname}</span><br/>
          <span>Referral Number : {status.number}</span><br/>
          <span>Referral Email : {status.email}</span><br/>
      
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button 
          onClick={()=>{
            Object.assign(status, {accept_by:auth.name});
            hnadlesubmitereferral(status)

          }}
           data-bs-dismiss="modal" aria-label="Close" type="button" className="btn btn-primary">Accept</button>
        </div>
			</div>
      </div>
    </div>
  </div>
    
  )
}
