import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ChangeEmail, PostOtp } from '../Redux/action/userAction';
import { store } from '../Redux/store';

export default function ChangeEmali() {
const [FormState, setFormState] = useState()
const [Otp, SetOtp] = useState()

const  [status, setstauts] = useState(true)
const dispatch = useDispatch()  
const auth = useSelector((state) => state.authReducer);

    function handleChange(e) {
        const { name, value } = e.target;
        setFormState({
          ...FormState,
          [name]: value,
          ['_id']:auth._id,
          ['name']:auth.name
        });
      }

    const handleOtpsubmit = (e) =>{
        dispatch(PostOtp(Otp))
       
    }

    function handleOtpChange(e) {
        const { name, value } = e.target;
        SetOtp({
          ...Otp,
          [name]: value,
          ['_id']:auth._id,
          ['name']:auth.name,
        });
      }

    const handlesubmit = async(e) =>{
        e.preventDefault();

            await axios
              .post(`${process.env.API_URL}/users/changeemail`, FormState)
              .then((e) => {
                  toast.success(e.data, {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });

                      setstauts(false)
                      
              })
              .catch((error) => {
                  toast.error(error.response?.data, {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
                console.log(error.response?.data);
                
              });
        
    }

    
  return (
    <div id="email_tab" className="tab-pane fade">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Change Email</h5>
        <div className="row">
          <div className="col-md-10 col-lg-6">
            {status ? ( <form onSubmit={handlesubmit} >
              <div className="col-lg-12">
                <label>New Email</label>
                <div className="input-group">
                  <input type="email"
                  onChange={handleChange}
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  required
                  />
                </div>
              </div>
              
              <button className="btn btn-primary mt-5" type="submit">
                Save Changes
              </button>
            </form>):( 
                <div>
              <div className="col-lg-12">
                <label>Enter Otp</label>
                <div className="input-group">
                  <input type="text"
                  onChange={(e)=>handleOtpChange(e)}
                  name="otp"
                  className="form-control"
                  placeholder="Enter Otp"
                  required
                  />
                </div>
                <div>
                    <br></br>
                    <p>
                        Please check the SPAM folder for OTP if mail not received.
                    </p>
                </div>
              </div>
              
              <button className="btn btn-primary mt-5" onClick={(e)=>handleOtpsubmit()}>
                Save Changes
              </button>
              </div>
          )}
           
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
