import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Approuter from './approuter'
import { loadUser } from './Redux/action/authAction'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'

export default function App() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector((state) => state.authReducer);

    useEffect(()=>{
        dispatch(loadUser())
       
    },[dispatch])
    
  return (
    <div>
      <ToastContainer 
        
      />
      
        <Approuter />
      
    </div>
  )
}
