
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Logo from '../assets/img/logo-white.png';
import Loginbg from '../assets/img/login.jpg';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { signUp } from '../Redux/action/authAction';
import { useSelector } from 'react-redux';

const Register = () => {

	const [formState, setFormState] = useState({})
	const auth = useSelector((state) => state.authReducer);

	const dispatch = useDispatch()
	function handleChange(e) {
		const { name, value } = e.target
		setFormState({
		  ...formState,
		  [name]: value,
		});
	  }

	const handleSubmit = (e)=>{
		e.preventDefault();
		if(formState.password!=formState.confirmPassword){
			// console.log(formState)
			toast.warn(`both passwords are not matching`, {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				});
		}
		else{
			const data = {
				name:formState.name,
				username:formState.username.toUpperCase(),
				email:formState.email,
				password:formState.password
			}
			dispatch(signUp(data,auth))
		}
	
		
	}
	
    return (
	<>
		{/* <!-- Main Wrapper --> */}
        <div className="main-wrapper login-body">
            <div className="login-wrapper">
            	<div className="container">
                	<div className="loginbox">
                    	<div className="login-left" style={{ backgroundImage: 'url('+Loginbg+')', }}>
                        <img className="img-fluid" src={Logo} alt="Logo" />
                        </div>
                        <div className="login-right">
							<div className="login-right-wrap">
								<h1>Register</h1>
								<p className="account-subtitle">Access to our dashboard</p>
								
								{/* <!-- Form --> */}
								<form onSubmit={handleSubmit} action="/login">
									<div className="form-group">
										<input onChange={handleChange} className="form-control" name="name" type="text" placeholder="Name" required />
									</div>
									<div className="form-group">
										<input onChange={handleChange} className="form-control" name="username" type="text" placeholder="Username" required />
									</div>
									<div className="form-group">
										<input onChange={handleChange} className="form-control" name="email" type="text" placeholder="Email" required/>
									</div>
									<div className="form-group">
										<input className="form-control" onChange={handleChange} name="password" type="text" placeholder="Password" required/>
									</div>
									<div className="form-group">
										<input name='confirmPassword' onChange={handleChange} className="form-control" type="text" placeholder="Confirm Password"/>
									</div>
									<div className="form-group mb-0">	
									<button type="submit" className="btn btn-primary"> Register</button>

										{/* <a href="/login" className="btn btn-primary btn-block">Register</a> */}
									</div>
								</form>
								{/* <!-- /Form --> */}
								
								<div className="login-or">
									<span className="or-line"></span>
									<span className="span-or">or</span>
								</div>
								
								{/* <!-- Social Login --> */}
								<div className="social-login">
									<span>Register with</span>
									<a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a><a href="#" className="google"><i className="fab fa-google"></i></a>
								</div>
								{/* <!-- /Social Login --> */}
								
								<div className="text-center dont-have">Already have an account? <Link to="/login">Login</Link></div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		{/* <!-- /Main Wrapper --> */}
</>
    );
}

export default  Register;
