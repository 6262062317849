
export const GET_USERS="GET_USERS"
export const GET_USERS_STATUS = "GET_USERS_STATUS";


export const usersReducer = (store={data:[]},{type,payload})=>{
    // console.log(type,payload)
    switch(type){
     case GET_USERS:
         return {...store,data:payload}
        //  case GET_USERS_STATUS:
        //  return {...store,data:payload}

        default:
            return store
    }
}

export default usersReducer;
 